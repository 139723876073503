import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Badge, Stack, styled } from '@mui/material';
import { getTeamFlagSrc } from '../../utils/FlagImages';

const SmallAvatar = styled(Avatar)(() => ({
  width: 26,
  height: 26,
}));

type AvatarWithChampionProps = {
  sx?: React.CSSProperties;
  src?: string;
  predictedChampionId?: string;
};

const AvatarWithChampion = ({ sx, src, predictedChampionId }: AvatarWithChampionProps) => {
  return (
    <Stack direction='row' spacing={2}>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<SmallAvatar src={getTeamFlagSrc(predictedChampionId)} />}>
        <Avatar src={src} sx={sx} />
      </Badge>
    </Stack>
  );
};

export default AvatarWithChampion;
