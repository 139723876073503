import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Game, PoolUser } from '../../../generated';
import MainCard from '../../../components/layout/cards/MainCard';
import { Divider, Grid, Typography } from '@mui/material';
import { formatDateTimeSeparate } from '../../../utils/TimeUtils';
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from 'react-web-share';

interface CurrentGamePredictionsShareButtonProps {
  text: string;
}

const CurrentGamePredictionsShareButton: React.FC<CurrentGamePredictionsShareButtonProps> = ({
  text,
}) => {
  return (
    <div>
      <RWebShare
        data={{
          text: text,
          title: 'Predictions',
        }}
        onClick={() => {
          console.log('shared successfully!');
        }}>
        <ShareIcon sx={{ cursor: 'pointer' }} />
      </RWebShare>
    </div>
  );
};

function generateSharetext(users: PoolUser[], games: Game[]): string {
  let shareText = '';

  games.forEach(game => {
    shareText += `\n*${game.team_home?.name} - ${game.team_away?.name}*\n\n`;

    // Sort users by their total score in descending order to determine their current position
    const sortedUsers = [...users].sort((a, b) => b.total_score - a.total_score);

    sortedUsers.forEach((user, index) => {
      shareText += `${user.name} (${user.total_score.toFixed(0)} pts)\n`;

      const predictionsForGame = user.past_predictions.filter(
        prediction => prediction.game.id === game.id,
      );

      if (predictionsForGame.length > 0) {
        predictionsForGame.forEach(prediction => {
          const scoreHome = prediction.score_home ?? '-';
          const scoreAway = prediction.score_away ?? '-';
          const possibleOdds = prediction.odds_with_toto ?? 0;

          shareText += `\t*${scoreHome} - ${scoreAway}* (_+${possibleOdds.toFixed(0)}_)\n`;
        });
      }
    });

    shareText += '\n';
  });

  return shareText;
}

interface CurrentGamePredictionsProps {
  users: PoolUser[];
}

const CurrentGamePredictions: React.FC<CurrentGamePredictionsProps> = ({ users }) => {
  // goes through all users' past_predictions and filters out the ones that are older than 2.5 hours
  // -> thus games that are not currently being played
  const now = new Date();
  const filteredPredictions = users.map(user => ({
    ...user,
    past_predictions: user.past_predictions.filter(prediction => {
      const gameDate = new Date(prediction.game.datetime);
      const twoHoursAfterGame = new Date(gameDate.getTime() + 2.5 * 60 * 60 * 1000);
      return now < twoHoursAfterGame;
    }),
  }));

  const uniqueGames: Game[] = [];
  filteredPredictions.forEach(user => {
    user.past_predictions.forEach(prediction => {
      if (!uniqueGames.some(game => game.id === prediction.game.id)) {
        uniqueGames.push(prediction.game);
      }
    });
  });

  if (uniqueGames.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <MainCard
        title='Predictions current game(s)'
        contentSX={{ padding: 2 }}
        boxShadow={true}
        border={true}
        rightTitle={
          <CurrentGamePredictionsShareButton
            text={generateSharetext(filteredPredictions, uniqueGames)}
          />
        }>
        {uniqueGames.map(game => (
          <div style={{ padding: '10px' }} key={game.id}>
            <Typography variant='h5'>
              {game.team_home?.name} - {game.team_away?.name}
            </Typography>
            <Typography variant='body1'>{formatDateTimeSeparate(game.datetime).date}</Typography>
            <Typography variant='body1'>{formatDateTimeSeparate(game.datetime).time}</Typography>
            <List>
              {filteredPredictions.map((user, index) => (
                <div key={index}>
                  {user.past_predictions
                    .filter(prediction => prediction.game.id === game.id)
                    .map((prediction, index) => (
                      <ListItem key={index}>
                        <div>
                          <ListItemText primary={user.name} />
                          <ListItemText
                            primary={`${prediction.score_home} - ${prediction.score_away}`}
                          />
                        </div>
                      </ListItem>
                    ))}
                </div>
              ))}
            </List>
            <Divider />
          </div>
        ))}
      </MainCard>
    </Grid>
  );
};

export default CurrentGamePredictions;
