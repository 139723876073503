import MainCard from '../../components/layout/cards/MainCard';
import { ExplanationText } from './ExplanationText';
import React from 'react';

export const ExplanationLoggedIn = () => {
  return (
    <MainCard>
      <ExplanationText showButtons={false} />
    </MainCard>
  );
};
