import { base64StringToBlob, blobToBase64String, blobToDataURL, dataURLToBlob } from 'blob-util';

import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import MainCard from '../../components/layout/cards/MainCard';
import { enqueueSnackbar } from 'notistack';
import LoadingCard from '../../components/layout/cards/LoadingCard';
import { useApi } from '../../components/providers/ApiProvider';
import { V1Service } from '../../generated';

const AVATAR_SIZE = 200;

type ProfileData = {
  username?: string;
  email?: string;
  display_name?: string;
  avatar?: string | null;
};

const cropAndResizeImage = (image: HTMLImageElement, size: number): string => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Calculate the cropping dimensions
  const aspectRatio = image.width / image.height;
  let cropWidth, cropHeight;
  if (aspectRatio > 1) {
    cropWidth = image.height;
    cropHeight = image.height;
  } else {
    cropWidth = image.width;
    cropHeight = image.width;
  }

  // width and height to provided size
  canvas.width = size;
  canvas.height = size;

  // Crop and resize the image
  ctx?.drawImage(
    image,
    (image.width - cropWidth) / 2,
    (image.height - cropHeight) / 2,
    cropWidth,
    cropHeight,
    0,
    0,
    size,
    size,
  );

  // Convert the canvas content to base64
  return canvas.toDataURL('image/jpeg');
};

async function updateUser(api: typeof V1Service, data: ProfileData, isAvatarEdited: boolean) {
  let avatarData;
  if (data.avatar && isAvatarEdited) {
    const blob = dataURLToBlob(data.avatar);
    avatarData = await blobToBase64String(blob);
  }
  try {
    await api.v1UsersMePartialUpdate({ ...data, avatar: avatarData });
    enqueueSnackbar('Profile updated', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Profile update failed', { variant: 'error' });
  }
}

const Profile: React.FC = () => {
  const api = useApi();
  const [isEditing, setEditing] = useState(false);
  const [isAvatarEdited, setAvatarEdited] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileData, setProfileData] = useState<ProfileData>({
    username: '',
    email: '',
    display_name: '',
    avatar: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.v1UsersMeRetrieve();
      let avatarData;
      if (response.avatar) {
        avatarData = await blobToDataURL(base64StringToBlob(response.avatar, 'image/jpg'));
      }
      setProfileData({
        username: response.name,
        email: response.email,
        display_name: response.name,
        avatar: avatarData,
      });
      setProfileLoading(false);
    };
    void fetchData();
  }, []);

  const handleEditClick = async () => {
    if (isEditing) {
      await updateUser(api, profileData, isAvatarEdited);
    }
    setEditing(!isEditing);
  };

  const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setProfileData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const result = cropAndResizeImage(img, AVATAR_SIZE);
          setProfileData({ ...profileData, avatar: result });
          setAvatarEdited(true); // Set isAvatarEdited to true when the avatar is changed
        };
      };

      reader.readAsDataURL(file);
    }
  };

  if (profileLoading) {
    return <LoadingCard loadingText='Loading profile...' />;
  }

  return (
    <div>
      <MainCard>
        <CardContent>
          <Grid container direction='column' alignItems='center'>
            <Avatar
              alt='User Avatar'
              src={profileData.avatar ? profileData.avatar : undefined}
              sx={{ width: 100, height: 100 }}
            />
            {isEditing ? (
              <>
                <input
                  accept='image/*'
                  style={{ display: 'none' }}
                  id='avatar-upload-button'
                  type='file'
                  onChange={handleAvatarChange}
                />
                <label htmlFor='avatar-upload-button'>
                  <IconButton
                    color='primary'
                    aria-label='upload picture'
                    component='span'
                    style={{ float: 'right' }}>
                    <CloudUploadIcon />
                  </IconButton>
                </label>
              </>
            ) : null}
            <Typography variant='h5' gutterBottom sx={{ marginTop: 5 }}>
              {isEditing ? (
                <TextField
                  label='Name'
                  defaultValue={profileData.username}
                  variant='outlined'
                  onChange={handleInputChange('username')}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              ) : (
                profileData.username
              )}
            </Typography>
            <Typography color='textSecondary'>{profileData.email}</Typography>

            <Grid container direction='row' alignItems='center' justifyContent='center' spacing={1}>
              <Grid item>
                <IconButton onClick={handleEditClick}>
                  {isEditing ? <CheckIcon /> : <EditIcon />}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='body1'>{isEditing ? 'Save' : 'Edit'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </div>
  );
};

export default Profile;
