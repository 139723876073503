import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import './App.css';

import Home from './pages/home/Home';
import Pools from './pages/pools/Pools';

import { OpenAPI } from './generated/core/OpenAPI';
import Layout from './components/layout/Layout';
import Page404 from './pages/error/Page404';
import Page500 from './pages/error/Page500';
import CreatePool from './pages/pools/components/CreatePool';
import PoolSingle from './pages/pools/PoolSingle';
import Profile from './pages/profile/Profile';
import PredictionContainer from './pages/predictions/components/PredictionContainer';
import Predictions from './pages/predictions/Predictions';
import { AuthContext } from './components/providers/AuthProvider';
import { useApi } from './components/providers/ApiProvider';
import Register from './pages/login-register/Register';
import Login from './pages/login-register/Login';
import { useAsync } from 'react-use';
import JoinPoolFromInvite from './pages/pools/JoinPoolFromInvite';

import Landing from './pages/landing/Landing';
import Explanation from './pages/explanation/Explanation';
import ForgotPassword from './pages/login-register/ForgotPassword';
import ResetPassword from './pages/login-register/ResetPassword';
import { ExplanationLoggedIn } from './pages/explanation/ExplanationLoggedIn';
import GoogleAuthHandler from './pages/login-register/GoogleAuthHandler';
import { saveInviteTokenFromURLToLocalStorage } from './utils/Url';
import Unsubscribe from './pages/login-register/Unsubscribe';

OpenAPI.BASE = import.meta.env.VITE_API_URL ?? 'http://localhost:8000';
OpenAPI.WITH_CREDENTIALS = true;

// @ts-expect-error - Cannot define correct type, fix later
OpenAPI.HEADERS = async () => {
  const token = localStorage.getItem('token');
  if (!token) return undefined;
  return {
    Authorization: `Bearer ${token}`,
  };
};

interface AppProps {
  as?: React.ElementType;
}

const App: React.FC<AppProps> = ({ as: RouterComponent = Router }) => {
  const api = useApi();
  const games = useAsync(api.v1GamesList);

  return (
    <div>
      <Routes>
        <Route path='/landing' element={<Landing />} />
        <Route path='/explanation' element={<Explanation />} />
        <Route path='/login/*' element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/resetpassword/:uid/:token' element={<ResetPassword />} />
        <Route path='/register' element={<Register />} />
        <Route path='/auth/google/callback' element={<GoogleAuthHandler />} />
        <Route path='/unsubscribe/:token' element={<Unsubscribe />} />

        <Route
          path='/*'
          element={
            <RequireAuth>
              <Layout>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path='/home' element={<Home />} />

                  <Route path='/pools' element={<Pools />} />
                  <Route path='/pool/:poolID' element={<PoolSingle />} />
                  <Route path='/pool/join' element={<JoinPoolFromInvite />} />
                  <Route path='/createpool' element={<CreatePool />} />

                  <Route path='/predictions' element={<Predictions games={games} />} />
                  <Route path='/predictions/:id' element={<PredictionContainer games={games} />} />

                  <Route path='/profile' element={<Profile />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/explanations' element={<ExplanationLoggedIn />} />

                  <Route path='/500page' element={<Page500 />} />
                  <Route path='*' element={<Page404 />} />
                </Routes>
              </Layout>
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const { token } = React.useContext(AuthContext);
  const location = useLocation();

  saveInviteTokenFromURLToLocalStorage();

  if (!token) {
    // If the user is not logged in, redirect to the landing page
    return <Navigate to='/landing' state={{ from: location }} replace />;
  }

  // if user came from invite link, redirect to join pool page
  const inviteToken = localStorage.getItem('inviteToken');
  if (inviteToken && location.pathname == '/') {
    return <Navigate to={`/pool/join?inviteToken=${inviteToken}`} />;
  }

  return children;
}
