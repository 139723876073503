import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../components/providers/ApiProvider';
import { useAsync } from 'react-use';
import { Navigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import MainCard from '../../components/layout/cards/MainCard';

const JoinPoolFromInvite: React.FC = () => {
  const { t } = useTranslation();
  const api = useApi();

  const urlParams = new URLSearchParams(window.location.search);
  const inviteToken = urlParams.get('inviteToken');

  // if coming here from localstorage inviteToken, make sure it's cleaned
  localStorage.removeItem('inviteToken');

  if (!inviteToken) return <div>{t('JoinPool.NoInviteToken')}</div>;

  const join = useAsync(async () => {
    const result = await api.v1PoolsJoinCreate(inviteToken);
    enqueueSnackbar(t('JoinPool.JoinSuccess'), { variant: 'success' });
    return result;
  });

  if (join.loading) return <div>{t('JoinPool.Loading')}</div>;
  if (join.error)
    return (
      <MainCard title={t('JoinPool.AlreadyMemberError')} boxShadow={true} border={true}>
        {t('JoinPool.AlreadyMemberError')}
      </MainCard>
    );

  return <Navigate to={`/pool/${join.value?.id}/`} />;
};

export default JoinPoolFromInvite;
