import React, { useEffect, useState } from 'react';
import LoadingCard from '../../../components/layout/cards/LoadingCard';
import { Game } from '../../../generated';
import { useParams } from 'react-router';
import Prediction from '../PredictionSingle';
import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { useApi } from '../../../components/providers/ApiProvider';

interface PredictionContainerProps {
  games: AsyncState<Game[]>;
}

const PredictionContainer: React.FC<PredictionContainerProps> = ({ games }) => {
  const api = useApi();
  const { id } = useParams();
  if (id === null || id === undefined) return;
  const [intId, setIntId] = useState<number>(parseInt(id));

  const prediction = useAsync(async () => {
    return await api.v1PredictionsRetrieve(intId);
  }, [intId]);

  const game = useAsync(async () => {
    return await api.v1GamesRetrieve(intId);
  }, [intId]);

  useEffect(() => {
    setIntId(parseInt(id));
  }, [id]);

  if (prediction.loading || game.loading || games.loading) {
    return <LoadingCard height='810px' loadingText='Loading prediction...' />;
  }

  if (games.value == null || game.value == null) return;

  const maxGameId = games.value.length;

  return (
    <Prediction
      maxGameId={maxGameId}
      currentGameId={intId}
      game={game.value}
      prediction={prediction.value}
    />
  );
};

export default PredictionContainer;
