import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../providers/AuthProvider';
import LanguageDropdown from './LanguageDropdown';

const pages = [''];
const settings = ['profile', 'logout'];

const NavBar: React.FC = () => {
  const muiTheme = useTheme();
  const matchDownMd = useMediaQuery(muiTheme.breakpoints.down('md'));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleLogout } = React.useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const handleSettingClick = (setting: string): void => {
    if (setting === 'logout') {
      handleLogout();
      navigate('/logout');
    }
    if (setting === 'profile') {
      navigate('/profile');
    }
    handleCloseUserMenu();
  };

  return (
    <AppBar
      position='static'
      sx={{ marginLeft: 0, padding: 0, backgroundColor: muiTheme.palette.primary.light }}
      elevation={0}>
      <Container maxWidth={false} sx={{ marginLeft: '-16px', padding: 0, maxWidth: '2500px' }}>
        <Toolbar disableGutters sx={{ height: '60px' }}>
          <Typography
            variant='h6'
            noWrap
            component={Link}
            to='/'
            sx={{
              marginLeft: 0,
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: muiTheme.palette.text.primary,
              textDecoration: 'none',
            }}>
            <img
              src='/ChipOnlyLogoNoBackground.png'
              alt='LOGO-text'
              style={{
                marginLeft: matchDownMd ? '20px' : '0px',
                height: '46px',
                width: 'auto',
                maxWidth: '100%',
              }}
            />
          </Typography>
          <Typography
            variant='h4'
            sx={{
              color: muiTheme.palette.secondary.light,
              fontWeight: 'bold',
              fontSize: { xs: '1rem', sm: '1rem', md: '2rem' },
            }}>
            GOAL GAMBLERS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map(page => (
                <MenuItem
                  key={t(page)}
                  onClick={() => {
                    navigate(`/${page.toLowerCase()}`);
                    handleCloseNavMenu();
                  }}>
                  <Typography textAlign='center'>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { sm: 'none', md: 'flex' } }}></Box>
          <LanguageDropdown />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('navbarTooltips.openSettings')}>
              <Button
                onClick={handleOpenUserMenu}
                color='inherit'
                style={{
                  width: '36px', // Set the width to 100px, adjust as needed
                  height: '36px', // Setting height
                  borderRadius: '10px',
                  // backgroundColor: palette.primary.main, // Set the background color to grey
                }}>
                <AccountCircleOutlinedIcon
                  style={{
                    color: muiTheme.palette.text.primary,
                  }}
                />
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                style: {
                  backgroundColor: muiTheme.palette.primary.light,
                },
              }}>
              {settings.map(setting => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleSettingClick(setting);
                  }}
                  sx={{ display: 'flex', alignItems: 'center' }} // Ensure items are aligned correctly
                >
                  {setting === 'profile' ? (
                    <PersonOutlineIcon sx={{ mr: 1, color: muiTheme.palette.text.primary }} />
                  ) : null}
                  {setting === 'logout' ? (
                    <LogoutIcon sx={{ mr: 1, color: muiTheme.palette.text.primary }} />
                  ) : null}
                  <Typography sx={{ color: muiTheme.palette.text.primary }} textAlign='center'>
                    {t(setting)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
