import React, { useEffect } from 'react';
import { useApi } from '../../../components/providers/ApiProvider';
import { enqueueSnackbar } from 'notistack';
import {
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Team } from '../../../generated';
import { roundFloat } from '../../../utils/Format';
import { useTranslation } from 'react-i18next';

interface ChampionPredictionProps {
  teams: Team[];
  disabled: boolean;
}

export const ChampionPrediction: React.FC<ChampionPredictionProps> = ({ teams, disabled }) => {
  const { t } = useTranslation();
  const api = useApi();
  const theme = useTheme();
  const [selectedChampionId, setSelectedChampionId] = React.useState<number | null | undefined>(
    null,
  );
  // using a counter instead of boolean here so that a user can change this multiple times
  const [saveCounter, setSaveCounter] = React.useState(0);

  const potentialPoints = roundFloat(
    teams.find(team => team.id === selectedChampionId)?.championship_points,
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.v1UsersMePredictedChampionRetrieve();
      setSelectedChampionId(response.predicted_champion);
    };
    void fetchData();
  }, []);

  useEffect(() => {
    async function updatePredictedChampion() {
      if (saveCounter > 0) {
        try {
          await api.v1UsersMePredictedChampionUpdate({ predicted_champion: selectedChampionId });
          enqueueSnackbar('Predicted champion updated', { variant: 'success' });
        } catch (error) {
          enqueueSnackbar('Error updating predicted champion', { variant: 'error' });
        }
      }
    }
    void updatePredictedChampion();
  }, [saveCounter]);

  const handleChange = (event: any) => {
    setSelectedChampionId(event.target.value);
  };

  const handleClose = (event: any) => {
    setSaveCounter(saveCounter + 1);
  };

  const sortedTeams = [...teams].sort((a, b) => a.championship_points - b.championship_points);

  return (
    <List style={{ backgroundColor: theme.palette.primary.light }}>
      <ListItem style={{ padding: '10px' }}>
        <ListItemIcon>
          <EmojiEventsIcon style={{ color: theme.palette.secondary.main, fontSize: '31px' }} />
        </ListItemIcon>
        <ListItemText
          primary={t('Other.WhoChampion')}
          primaryTypographyProps={{ fontSize: '20px' }}
          secondaryTypographyProps={{ fontSize: '17px' }}></ListItemText>
      </ListItem>
      <ListItem style={{ padding: '10px' }}>
        <ListItemIcon />
        <ListItemText>
          <FormControl fullWidth style={{ marginBottom: theme.spacing(2) }}>
            <Select
              value={selectedChampionId}
              onChange={handleChange}
              onClose={handleClose}
              disabled={disabled}
              renderValue={selected => {
                const selectedTeam = teams.find(team => team.id === selected);
                return selectedTeam ? selectedTeam.name : '';
              }}
              style={{ backgroundColor: theme.palette.primary.light, maxWidth: '330px' }}>
              {sortedTeams.map(team => (
                <MenuItem key={team.id} value={team.id}>
                  <div style={{ width: '100%' }}>
                    {team.name}
                    <span style={{ float: 'right' }}>{roundFloat(team.championship_points)} </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>
            {potentialPoints ? t('Other.PotentialPoints') + ': ' + potentialPoints : ''}
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
};
