import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Typography,
  Divider,
  Grid,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { roundToOneDecimal } from '../../../utils/Format';
import { useTranslation } from 'react-i18next';

interface PayoutMatrixProps {
  matrix: number[][];
  scorematrix: number[][];
  selectedHomeScore?: number | null;
  selectedAwayScore?: number | null;
  homeTeamName: string;
  awayTeamName: string;
  homeTeamScore: number;
  awayTeamScore: number;
}

const PayoutMatrix: React.FC<PayoutMatrixProps> = ({
  matrix,
  scorematrix,
  selectedHomeScore,
  selectedAwayScore,
  homeTeamName,
  awayTeamName,
  homeTeamScore,
  awayTeamScore,
}) => {
  const isMobile = useMediaQuery('(max-width:780px)');
  const { t } = useTranslation();

  const cellStyle = isMobile
    ? { padding: '5px', fontSize: '10px', textAlign: 'center' as const }
    : { textAlign: 'center' as const };

  const tableStyle = isMobile
    ? { minWidth: 45, width: '100%', display: 'table', marginLeft: 'auto', marginRight: 'auto' }
    : { maxWidth: 550, marginLeft: 'auto', marginRight: 'auto' };
  const selectedStyle = { ...cellStyle, backgroundColor: '#b7e2b7' };
  const lightSelectedStyle = { ...cellStyle, backgroundColor: '#ecf9ec' };
  const lightGreyStyle = { ...cellStyle, backgroundColor: '#D3D3D3' };
  const darkGreyStyle = { ...cellStyle, backgroundColor: '#A9A9A9' };

  const predictedHomeScore = selectedHomeScore ?? -1;
  const predictedAwayScore = selectedAwayScore ?? -1;
  const theme = useTheme();

  const upperSelectedStyle = {
    ...cellStyle,
    backgroundColor: '#b7e2b7',
    width: '33%',
    textAlign: 'center' as const,
  };
  const upperCellStyle = {
    padding: isMobile ? '7px' : '16px',
    border: 'none',
    width: '33%',
    textAlign: 'center' as const,
  };
  const upperGreyStyle = {
    ...cellStyle,
    backgroundColor: '#A9A9A9',
    width: '33%',
    textAlign: 'center' as const,
  };

  const calculateTotalOdds = (values: number[]): number => {
    const totalInverse = values.reduce((acc, value) => acc + 1 / value, 0);
    return 1 / totalInverse;
  };

  const slipStyleLeft = {
    padding: '1px 16px 1px 16px',
    border: 'none',
    textAlign: 'left' as const,
  };
  const slipStyleRight = {
    padding: '1px 16px 1px 16px',
    border: 'none',
    fontWeight: 'bold',
    textAlign: 'right' as const,
  };

  const rowTotals = scorematrix.map(row => calculateTotalOdds(row));
  const columnTotals = scorematrix[0].map((_, colIndex) =>
    calculateTotalOdds(scorematrix.map(row => row[colIndex])),
  );

  const selectedRowTotal = selectedHomeScore == null ? 0 : rowTotals[predictedHomeScore];
  const selectedColumnTotal = selectedAwayScore == null ? 0 : columnTotals[predictedAwayScore];
  let selectedTotal;
  if (
    predictedHomeScore >= 0 &&
    predictedAwayScore >= 0 &&
    predictedHomeScore < matrix.length &&
    predictedAwayScore < matrix[predictedHomeScore].length
  ) {
    selectedTotal = matrix[predictedHomeScore][predictedAwayScore];
  } else {
    selectedTotal = 0;
  }

  const calculateOutcomeOdds = () => {
    let homeVictorySum = 0;
    let drawSum = 0;
    let awayVictorySum = 0;

    for (let i = 0; i < scorematrix.length; i++) {
      for (let j = 0; j < scorematrix[i].length; j++) {
        const inverseOdds = 1 / scorematrix[i][j];
        if (i > j) homeVictorySum += inverseOdds;
        else if (i === j) drawSum += inverseOdds;
        else awayVictorySum += inverseOdds;
      }
    }

    const homeVictoryOdds = 1 / homeVictorySum;
    const drawOdds = 1 / drawSum;
    const awayVictoryOdds = 1 / awayVictorySum;

    return { homeVictoryOdds, drawOdds, awayVictoryOdds };
  };
  const { homeVictoryOdds, drawOdds, awayVictoryOdds } = calculateOutcomeOdds();

  type GameOutcome = 'homeVictory' | 'awayVictory' | 'draw' | 'none';

  let selectedOutcome: GameOutcome = 'none';
  let predictedOutcomeValue;
  if (predictedHomeScore >= 0 && predictedAwayScore >= 0) {
    if (predictedHomeScore > predictedAwayScore) {
      selectedOutcome = 'homeVictory';
      predictedOutcomeValue = homeVictoryOdds ?? 0;
    } else if (predictedHomeScore < predictedAwayScore) {
      selectedOutcome = 'awayVictory';
      predictedOutcomeValue = awayVictoryOdds ?? 0;
    } else if (predictedHomeScore == predictedAwayScore) {
      selectedOutcome = 'draw';
      predictedOutcomeValue = drawOdds ?? 0;
    } else {
      selectedOutcome = 'none';
      predictedOutcomeValue = 0;
    }
  }
  let gameOutcomeValue: GameOutcome = 'none';
  if (homeTeamScore > awayTeamScore) {
    gameOutcomeValue = 'homeVictory';
  } else if (homeTeamScore < awayTeamScore) {
    gameOutcomeValue = 'awayVictory';
  } else if (homeTeamScore == awayTeamScore) {
    gameOutcomeValue = 'draw';
  }

  const fullScoreCorrect = selectedTotal - selectedRowTotal - selectedColumnTotal;
  const nonNegativeFullScoreCorrect = fullScoreCorrect >= 0 ? fullScoreCorrect : 0;

  const getCellStyle = (homeScoreColumn: number, awayScoreColumn: number) => {
    let style = { ...cellStyle };
    if (homeScoreColumn === homeTeamScore && awayScoreColumn === awayTeamScore) {
      style = { ...style, ...selectedStyle };
    } else if (homeScoreColumn === homeTeamScore || awayScoreColumn === awayTeamScore) {
      style = { ...style, ...lightSelectedStyle };
    } else if (homeScoreColumn === predictedHomeScore || awayScoreColumn === predictedAwayScore) {
      style = { ...style, ...lightGreyStyle };
    }
    if (homeScoreColumn === predictedHomeScore && awayScoreColumn === predictedAwayScore) {
      style = { ...style, border: '2px solid black' } as any;
    }

    return style;
  };
  const getUpperCellStyle = (outcomeType: GameOutcome) => {
    if (gameOutcomeValue === outcomeType) {
      return upperSelectedStyle;
    } else if (selectedOutcome === outcomeType && selectedOutcome !== gameOutcomeValue) {
      return upperGreyStyle;
    } else {
      return upperCellStyle;
    }
  };

  const isPerfectPrediction =
    predictedAwayScore === awayTeamScore && predictedHomeScore === homeTeamScore;

  return (
    <Grid container justifyContent='center' alignItems='center' spacing={2}>
      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={10}>
        <TableContainer
          component={Paper}
          style={{ width: '100%', backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={getUpperCellStyle('homeVictory')}>
                  <Typography>{homeTeamName} </Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {gameOutcomeValue === 'homeVictory' ? roundToOneDecimal(homeVictoryOdds) : 0}
                  </Typography>
                </TableCell>
                <TableCell style={getUpperCellStyle('draw')}>
                  <Typography>{t('PayoutMatrix.Draw')}</Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {gameOutcomeValue === 'draw' ? roundToOneDecimal(drawOdds) : 0}
                  </Typography>
                </TableCell>
                <TableCell style={getUpperCellStyle('awayVictory')}>
                  <Typography>{awayTeamName} </Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {gameOutcomeValue === 'awayVictory' ? roundToOneDecimal(awayVictoryOdds) : 0}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={10}>
        <TableContainer
          component={Paper}
          elevation={0}
          style={{ backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('PayoutMatrix.HomeScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(
                    predictedHomeScore === homeTeamScore ? selectedRowTotal ?? 0 : 0,
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('PayoutMatrix.AwayScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(
                    predictedAwayScore === awayTeamScore ? selectedColumnTotal ?? 0 : 0,
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('PayoutMatrix.FullScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {isPerfectPrediction
                    ? roundToOneDecimal(nonNegativeFullScoreCorrect - (predictedOutcomeValue ?? 0))
                    : roundToOneDecimal(0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('PayoutMatrix.TotoCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(
                    gameOutcomeValue === selectedOutcome ? predictedOutcomeValue ?? 0 : 0,
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('PayoutMatrix.TotalPointsScored')}</TableCell>
                <TableCell
                  style={{
                    ...slipStyleRight,
                    borderTop: '1px solid #000',
                  }}>
                  {roundToOneDecimal(selectedTotal)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          style={{ ...tableStyle, backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={cellStyle}> </TableCell>
                {[0, 1, 2, 3, 4, 5].map(score => (
                  <TableCell key={score} style={cellStyle}>
                    <b>{score !== 5 ? score : '5+'}</b>
                  </TableCell>
                ))}
                <TableCell style={cellStyle}>
                  <b>{t('PayoutMatrix.Home')}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[0, 1, 2, 3, 4, 5].map((homeScore, rowIndex) => (
                <TableRow key={homeScore}>
                  <TableCell component='th' scope='row' style={cellStyle}>
                    <b>{homeScore !== 5 ? homeScore : '5+'}</b>
                  </TableCell>
                  {[0, 1, 2, 3, 4, 5].map((awayScore, colIndex) => (
                    <TableCell key={awayScore} style={getCellStyle(homeScore, awayScore)}>
                      {roundToOneDecimal(matrix[homeScore][awayScore])}
                    </TableCell>
                  ))}
                  <TableCell
                    style={
                      rowIndex === homeTeamScore
                        ? selectedStyle
                        : rowIndex === predictedHomeScore
                          ? darkGreyStyle
                          : cellStyle
                    }>
                    <b>{homeTeamScore == rowIndex ? roundToOneDecimal(rowTotals[rowIndex]) : 0}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component='th' scope='row' style={cellStyle}>
                  <b>{t('PayoutMatrix.Away')}</b>
                </TableCell>
                {columnTotals.map((total, columnIndex) => (
                  <TableCell
                    key={`total-${columnIndex}`}
                    style={
                      columnIndex === awayTeamScore
                        ? selectedStyle
                        : columnIndex === predictedAwayScore
                          ? darkGreyStyle
                          : cellStyle
                    }>
                    <b>{awayTeamScore == columnIndex ? roundToOneDecimal(total) : 0}</b>
                  </TableCell>
                ))}
                <TableCell style={cellStyle}> </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PayoutMatrix;
