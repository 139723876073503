import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../components/providers/ApiProvider';
import { AuthContext } from '../../components/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const GoogleAuthHandler = () => {
  const navigate = useNavigate();
  const { handleLogin } = useContext(AuthContext);
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const existingToken = localStorage.getItem('token');
    if (existingToken) {
      navigate('/home');
      return;
    }

    async function handleGoogleResponse() {
      const code = new URLSearchParams(window.location.search).get('code');
      if (!code) {
        navigate('/login');
        return;
      }

      try {
        const response = await api.v1AuthGoogleCallbackRetrieve(code);
        if (response.access) {
          localStorage.setItem('token', response.access);

          const tokenCheck = localStorage.getItem('token');
          if (!tokenCheck) {
            throw new Error(t('googleAuth.errorTokenExchange'));
          }
          await handleLogin(null, response.access);
          navigate('/home');
        } else {
          throw new Error(t('googleAuth.errorTokenExchange'));
        }
      } catch (error) {
        enqueueSnackbar(t('googleAuth.errorTokenExchange'), {
          variant: 'error',
        });
      }
    }

    handleGoogleResponse().catch(error => {
      enqueueSnackbar(t('googleAuth.unhandledError', { error: error.message }), {
        variant: 'error',
      });
    });
  }, [navigate, enqueueSnackbar, handleLogin, api, t]);

  return <div>{t('googleAuth.processingLogin')}</div>;
};

export default GoogleAuthHandler;
