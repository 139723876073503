import React from 'react';
import MainCard from '../../components/layout/cards/MainCard';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const Page404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div data-testid='page404-component' className='App'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={t('404 - Page Not Found')}
            contentSX={{ padding: 2 }}
            boxShadow={true}
            border={true}>
            <div>
              <p>{t('error.404')}</p>
            </div>
          </MainCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Page404;
