import React from 'react';

import { ExplanationText } from './ExplanationText';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';

const Explanation: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 650px)');

  const style: React.CSSProperties = {
    backgroundColor: 'rgb(255 255 255 / 85%)',
    margin: 'auto',
    maxWidth: '650px',
    scrollbarWidth: 'thin',
    marginTop: '20px',
    overflowY: 'scroll',
    maxHeight: '90vh',
    padding: '20px',
    borderRadius: '10px',
  };

  if (isMobile) {
    style.margin = '8px';
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
      }}>
      <div style={style}>
        <button
          onClick={() => {
            navigate('/');
          }}
          style={{
            float: 'left',
            top: '20px',
            left: '20px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}>
          <ArrowBackIosIcon style={{ fontSize: 30 }} />
        </button>
        <ExplanationText showButtons={true} />
      </div>
    </div>
  );
};

export default Explanation;
