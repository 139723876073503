// LanguageDropdown.tsx
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import { Tooltip, useTheme } from '@mui/material';

import i18next from 'i18next';
import 'flag-icon-css/css/flag-icons.min.css';
import { useTranslation } from 'react-i18next';

const LanguageDropdown: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const palette = useTheme();
  const { t } = useTranslation();

  const languages = [
    { code: 'en', name: 'English', flag: 'gb' },
    { code: 'nl', name: 'Dutch', flag: 'nl' },
    { code: 'de', name: 'German', flag: 'de' },
    { code: 'es', name: 'Spanish', flag: 'es' },
    { code: 'fr', name: 'French', flag: 'fr' },
    { code: 'pl', name: 'Polish', flag: 'pl' },
    { code: 'hu', name: 'Hungarian', flag: 'hu' },
    { code: 'sv', name: 'Swedish', flag: 'se' }, // Watch out! Swedish flag is 'se' not 'sv'
    { code: 'it', name: 'Italian', flag: 'it' },
  ];

  // Sort languages alphabetically by name
  languages.sort((a, b) => a.name.localeCompare(b.name));

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (lang: string): void => {
    i18next.changeLanguage(lang).catch(console.error);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t('navbarTooltips.changeLanguage')}>
        <Button
          onClick={handleClick}
          color='inherit'
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '10px',
          }}>
          <LanguageIcon
            style={{
              color: palette.palette.text.primary,
            }}
          />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            backgroundColor: palette.palette.primary.light,
          },
        }}>
        {languages.map(language => (
          <MenuItem
            key={language.code}
            onClick={() => {
              handleLanguageSelect(language.code);
            }}>
            <span
              className={`flag-icon flag-icon-${language.flag}`}
              style={{ marginRight: '5px' }}></span>
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageDropdown;
