import { base64StringToBlob, blobToDataURL } from 'blob-util';

export const convertAvatar = async (avatar: string | null | undefined) => {
  let result;
  if (avatar) {
    const blob = base64StringToBlob(avatar, 'image/jpg');
    result = await blobToDataURL(blob);
  }
  return result;
};
