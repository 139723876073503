import React, { createContext } from 'react';
import { ApiError, CancelablePromise, V1Service } from '../../generated';
import { AuthContext } from './AuthProvider';

interface ApiContextType {
  api: typeof V1Service;
}

const ApiContext = createContext<ApiContextType>({ api: V1Service });

type Props = {
  children?: React.ReactNode;
};

const ApiProvider: React.FC<Props> = ({ children }) => {
  const { handleTokenRefresh } = React.useContext(AuthContext);

  async function withErrorHandling<T>(
    apiFunction: (...args: any[]) => CancelablePromise<T>,
    ...args: any[]
  ): Promise<T | undefined> {
    try {
      const result = await apiFunction(...args);
      return result;
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      if (error.status === 401) {
        try {
          await handleTokenRefresh();
          window.location.reload();
        } catch (error) {
          window.location.href = '/login';
        }
        return;
      }

      if (error.status === 403) {
        window.location.href = '/403page';
        return;
      }

      if (error.status >= 500) {
        window.location.href = '/500page';
        return;
      }

      // only throw if it's not any of the above errors so we
      // can handle it in the app. e.g show snackbar on 400 error
      throw error;
    }
  }

  const ApiClient = new Proxy(V1Service, {
    get(target, propKey, receiver) {
      // @ts-expect-error typescript doesn't like this but it's okay
      const origMethod = target[propKey];
      if (typeof origMethod === 'function') {
        return async (...args: any[]) => {
          return await withErrorHandling(origMethod.bind(target), ...args);
        };
      }
      return Reflect.get(target, propKey, receiver);
    },
  });

  const value = { api: ApiClient };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

const useApi = (): typeof V1Service => {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context.api;
};

export { ApiProvider, ApiContext, useApi };
