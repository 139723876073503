import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import MainCard from '../../components/layout/cards/MainCard';
import { enqueueSnackbar } from 'notistack';
import { useApi } from '../../components/providers/ApiProvider';
import { ApiError } from '../../generated';

const ResetPassword = () => {
  const api = useApi();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const passwordsMatch = password === confirmPassword;
  const canSubmit = password && confirmPassword && passwordsMatch;

  const isPasswordValid = (password: string) => {
    const minLength = 8;
    return password.length >= minLength || password.length < 1;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (typeof uid !== 'string' || typeof token !== 'string') {
      console.error('UID or token is missing.');
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      setIsLoading(false);
      return;
    }

    const requestBody = { password };

    try {
      const response = await api.v1UsersPasswordResetConfirmCreate(token, uid, requestBody);
      console.log(response);
      setIsLoading(false);
      if (response?.detail) {
        console.log('Password reset successfully', response);
        enqueueSnackbar('Password has been reset', {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        });
        navigate('/login');
      } else {
        console.error('Failed to reset password', response);
        enqueueSnackbar(
          response.password ? response.password.join(' ') : 'Failed to reset password',
          {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          },
        );
      }
    } catch (error) {
      setIsLoading(false);

      const apiError = error as ApiError;
      const errorDetail = apiError.body?.detail || 'An error occurred';

      enqueueSnackbar(errorDetail, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '480px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component='h1' variant='h5'>
              Set New Password
            </Typography>
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='password'
                label='New Password'
                name='password'
                type='password'
                autoComplete='new-password'
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='confirmPassword'
                label='Confirm New Password'
                name='confirmPassword'
                type='password'
                autoComplete='new-password'
                value={confirmPassword}
                onChange={e => {
                  setConfirmPassword(e.target.value);
                }}
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: 'darkgreen',
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                }}
                disabled={!canSubmit || isLoading}>
                Reset Password
              </Button>
              {!passwordsMatch && password && confirmPassword && (
                <Typography color='error' variant='body2' style={{ marginTop: '8px' }}>
                  Passwords do not match.
                </Typography>
              )}
              {!isPasswordValid(password) && (
                <Typography color='error' variant='body2' style={{ marginTop: '8px' }}>
                  Password is too short.
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </MainCard>
    </div>
  );
};

export default ResetPassword;
