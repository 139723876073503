import React from 'react';
import { Card, CardContent, CircularProgress, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LoadingCardProps {
  loadingText?: string;
  size?: number;
  height?: string;
}

export default function LoadingCard({
  loadingText = 'Loading...',
  size = 30,
  height = '100vh',
}: LoadingCardProps): JSX.Element {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: 1,
        height: height, // eslint-disable-line object-shorthand
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
        borderRadius: '10px',
      }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress size={size} sx={{ color: '#1066b0' }} />
          <Box sx={{ textAlign: 'center', marginTop: '10px' }}>{loadingText}</Box>
        </Box>
      </CardContent>
    </Card>
  );
}
