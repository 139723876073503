import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from 'react-web-share';
import { Typography } from '@mui/material';
import Button from '../../../components/layout/Button';

interface ShareInviteUrlButtonProps {
  inviteToken: string;
}

const ShareInviteUrlButton: React.FC<ShareInviteUrlButtonProps> = ({ inviteToken }) => {
  const poolShareLink = `${window.location.origin}/pool/join?inviteToken=${inviteToken}`;
  const inviteText = `Hey! Join my betting pool on Goalgamblers for fun!`;

  return (
    <div>
      <RWebShare
        data={{
          text: inviteText,
          title: 'Invite to pool!',
          url: poolShareLink,
        }}
        onClick={() => {
          console.log('shared successfully!');
        }}>
        <Button style={{ width: '100%' }}>
          <ShareIcon />
          <Typography marginLeft='5px'>Invite people to your pool!</Typography>
        </Button>
      </RWebShare>
    </div>
  );
};

export default ShareInviteUrlButton;
