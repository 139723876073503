import React from 'react';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../components/layout/cards/MainCard';
import Grid from '@mui/material/Grid';
import CreatePoolForm from './CreatePoolForm';

const CreatePool: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div data-testid='poolcreate-component' className='App' style={{ height: '100vh' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard title={t('Create new pool')} boxShadow={true} border={true}>
            <CreatePoolForm />
          </MainCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreatePool;
