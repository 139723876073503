import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface BasicPopoverProps {
  text: string;
}

export default function HelpPopover({ text }: BasicPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // replace newlines with <br> and replace **text** with <b>text</b>
  const formattedText = text.split('\n').map((line, index) => {
    const parts = line.split('**');
    return (
      <React.Fragment key={index}>
        {parts.map((part, i) => {
          if (i % 2 === 1) {
            return <b key={i}>{part}</b>;
          } else {
            return part;
          }
        })}
        <br />
      </React.Fragment>
    );
  });

  return (
    <div>
      <HelpOutlineIcon style={{ cursor: 'pointer' }} onClick={handleClick}></HelpOutlineIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography sx={{ p: 2 }}>{formattedText}</Typography>
      </Popover>
    </div>
  );
}
