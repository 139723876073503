import React, { useEffect, useState } from 'react';
import MainCard from '../../components/layout/cards/MainCard';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
} from '@mui/material';
import { Prediction as ApiPrediction, GamePayout } from '../../generated';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useApi } from '../../components/providers/ApiProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/TimeUtils';
import { useTheme } from '@mui/material/styles';
import ScoreMatrix from './components/ScoreMatrix';
import PayoutMatrix from './components/PayoutMatrix';
import GameText from '../../components/GameText';
import { useTranslation } from 'react-i18next';

interface PredictionProps {
  maxGameId: number;
  currentGameId: number;
  game: GamePayout;
  prediction: ApiPrediction | undefined | null;
}

const Prediction: React.FC<PredictionProps> = ({ maxGameId, currentGameId, game, prediction }) => {
  const { t } = useTranslation();
  const api = useApi();
  const { id } = useParams();
  const isMobile = useMediaQuery('@media (max-width: 780px)');
  const gameTextProps = isMobile ? { iconSize: '22px', style: { fontSize: '15px' } } : {};

  if (id === null || id === undefined) return;

  const [homeScore, setHomeScore] = useState<number | null | undefined>(prediction?.score_home);
  const [awayScore, setAwayScore] = useState<number | null | undefined>(prediction?.score_away);

  const [saving, setSaving] = useState(false);
  const theme = useTheme();

  const editable = game ? new Date(game.datetime) > new Date() : true;

  useEffect(() => {
    if (typeof homeScore === 'number' && typeof awayScore === 'number') {
      const isHomeScoreChanged = prediction?.score_home !== homeScore;
      const isAwayScoreChanged = prediction?.score_away !== awayScore;

      if (isHomeScoreChanged || isAwayScoreChanged) {
        void (async () => {
          try {
            await handleSave(homeScore, awayScore);
          } catch (error) {
            console.error('Failed to save scores:', error);
          }
        })();
      }
    }
  }, [homeScore, awayScore]);

  useEffect(() => {
    setHomeScore(prediction?.score_home);
    setAwayScore(prediction?.score_away);
  }, [prediction]);

  const handleSave = async (scoreHome: number, scoreAway: number) => {
    try {
      setSaving(true);
      if (id) {
        await api.v1PredictionsPartialUpdate(currentGameId, {
          score_home: scoreHome,
          score_away: scoreAway,
        });
        enqueueSnackbar('Prediction saved', { variant: 'success' });
      }
    } catch (error) {
      console.error('API call error:', error);
      enqueueSnackbar('Error saving prediction', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {!editable && (
            <MainCard
              title={t('PredictionsSinglePage.payoutTitle')}
              contentSX={{ padding: 2 }}
              boxShadow={true}
              border={true}>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Grid container alignItems='center' style={{ maxWidth: '550px' }}>
                  <Grid item xs={2}>
                    {currentGameId > 1 && (
                      <Link to={`/predictions/${currentGameId - 1}`}>
                        <ArrowBackIosIcon style={{ float: 'left', paddingBottom: '5px' }} />
                      </Link>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <GameText iconSize='20px' game={game} variant='body1' showScore={true} />
                  </Grid>
                  <Grid item xs={2}>
                    {currentGameId < maxGameId && (
                      <Link to={`/predictions/${currentGameId + 1}`}>
                        <ArrowForwardIosIcon style={{ float: 'right', paddingBottom: '5px' }} />
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <Typography variant='body1' gutterBottom>
                  {formatDate(game.datetime)}
                </Typography>
                <Box display='flex' alignItems='center' marginBottom='10px'>
                  <Select
                    labelId='home-score-select-label'
                    id='home-score-select'
                    value={homeScore !== null ? homeScore : ''}
                    disabled={!editable}
                    sx={{
                      width: '70px',
                      marginRight: '10px',
                    }}
                    MenuProps={{
                      sx: {
                        '.MuiPaper-root': {
                          backgroundColor: theme.palette.primary.light,
                        },
                      },
                    }}>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5+</MenuItem>
                  </Select>

                  <Typography variant='h6' gutterBottom>
                    -
                  </Typography>
                  <Select
                    labelId='away-score-select-label'
                    id='away-score-select'
                    value={awayScore !== null ? awayScore : ''}
                    disabled={!editable}
                    sx={{
                      width: '70px',
                      marginLeft: '10px',
                    }}
                    MenuProps={{
                      sx: {
                        '.MuiPaper-root': {
                          backgroundColor: theme.palette.primary.light,
                        },
                      },
                    }}>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5+</MenuItem>
                  </Select>
                </Box>
                {game.payout_matrix ? (
                  <PayoutMatrix
                    matrix={game.payout_matrix as unknown as number[][]}
                    scorematrix={game.odds as unknown as number[][]}
                    selectedHomeScore={homeScore}
                    selectedAwayScore={awayScore}
                    homeTeamName={game.team_home?.name ?? '?'}
                    awayTeamName={game.team_away?.name ?? '?'}
                    homeTeamScore={game.score_home ?? -1}
                    awayTeamScore={game.score_away ?? -1}
                  />
                ) : (
                  <Typography
                    variant='subtitle1'
                    color='textSecondary'
                    align='center'
                    marginTop='20px'>
                    {t('PredictionsSinglePage.payoutInProgress')}
                  </Typography>
                )}
              </Box>
            </MainCard>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={t('PredictionsSinglePage.predTitle')}
            contentSX={{ padding: 2 }}
            boxShadow={true}
            border={true}
            rightTitle={saving && <CircularProgress size={24} color='inherit' />}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Grid container alignItems='center' style={{ maxWidth: '550px' }}>
                <Grid item xs={2}>
                  {currentGameId > 1 && (
                    <Link to={`/predictions/${currentGameId - 1}`}>
                      <ArrowBackIosIcon style={{ float: 'left', paddingBottom: '5px' }} />
                    </Link>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <GameText game={game} variant='h6' {...gameTextProps} />
                    <Typography variant='body1' gutterBottom>
                      {formatDate(game.datetime)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {currentGameId < maxGameId && (
                    <Link to={`/predictions/${currentGameId + 1}`}>
                      <ArrowForwardIosIcon style={{ float: 'right', paddingBottom: '5px' }} />
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Box display='flex' alignItems='center' marginBottom='10px'>
                <Select
                  key={`home-${homeScore}`}
                  labelId='home-score-select-label'
                  id='home-score-select'
                  value={homeScore !== null ? homeScore : ''}
                  onChange={e => {
                    if (
                      e.target.value === '' ||
                      e.target.value === null ||
                      e.target.value === undefined
                    )
                      return;
                    const val = parseInt(e.target.value as string, 10);
                    setHomeScore(val);
                  }}
                  disabled={!editable}
                  sx={{
                    width: '70px',
                    marginRight: '10px',
                  }}
                  MenuProps={{
                    sx: {
                      '.MuiPaper-root': {
                        backgroundColor: theme.palette.primary.light,
                      },
                    },
                  }}>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                </Select>

                <Typography variant='h6' gutterBottom>
                  -
                </Typography>
                <Select
                  key={`away-${homeScore}`}
                  labelId='away-score-select-label'
                  id='away-score-select'
                  value={awayScore !== null ? awayScore : ''}
                  onChange={e => {
                    if (
                      e.target.value === '' ||
                      e.target.value === null ||
                      e.target.value === undefined
                    )
                      return;
                    const val = parseInt(e.target.value as string, 10);
                    setAwayScore(val);
                  }}
                  disabled={!editable}
                  sx={{
                    width: '70px',
                    marginLeft: '10px',
                  }}
                  MenuProps={{
                    sx: {
                      '.MuiPaper-root': {
                        backgroundColor: theme.palette.primary.light,
                      },
                    },
                  }}>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                </Select>
              </Box>

              {game.odds && (
                <ScoreMatrix
                  matrix={game.odds as unknown as number[][]}
                  selectedHomeScore={homeScore}
                  selectedAwayScore={awayScore}
                  homeTeamName={game.team_home?.name ?? '?'}
                  awayTeamName={game.team_away?.name ?? '?'}
                />
              )}
            </Box>
            {!editable && (
              <Typography color='error' align='center' style={{ margin: '10px 0' }}>
                {t('PredictionsSinglePage.cantChangeOdds')}
              </Typography>
            )}
          </MainCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Prediction;
