import React, { useEffect } from 'react';
import { CookieTokenObtainPair, V1Service } from '../../generated';
// implemented with help of https://www.robinwieruch.de/react-router-authentication/

type CreateTokenResponse = {
  access: string;
};

type Props = {
  children?: React.ReactNode;
};

type AuthContextValues = {
  token: string | undefined | null;
  handleLogin: (
    credentials: CookieTokenObtainPair | null,
    existing_token?: string,
  ) => Promise<void>;
  handleLogout: () => void;
  handleTokenRefresh: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextValues>({
  token: undefined,
  handleLogin: async () => {},
  handleLogout: () => {},
  handleTokenRefresh: async () => {},
});

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = React.useState<string | null>(localStorage.getItem('token'));

  const handleLogin = async (
    credentials: CookieTokenObtainPair | null,
    existingToken?: string,
  ): Promise<void> => {
    let accessToken: string;

    if (existingToken) {
      accessToken = existingToken;
    } else if (credentials) {
      const tokenResponse = (await V1Service.v1TokenCreate(
        credentials,
      )) as unknown as CreateTokenResponse;
      accessToken = tokenResponse.access;
    } else {
      throw new Error('No credentials or token provided');
    }

    setToken(accessToken);
    localStorage.setItem('token', accessToken);

    // Retrieve user data
    const userData = await V1Service.v1UsersMeRetrieve();
    localStorage.setItem('userId', userData.id.toString());
  };

  const handleLogout = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setToken(null);
  };

  useEffect(() => {
    const tokenRefreshInterval = setInterval(
      () => {
        if (token) {
          void handleTokenRefresh();
        }
      },
      1000 * 60 * 14,
    ); // Refresh every 14 minutes

    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, [token]);

  const handleTokenRefresh = async (): Promise<void> => {
    try {
      const tokenResponse = await V1Service.v1TokenRefreshCreate();
      if (!tokenResponse.access) throw new Error('Token refresh failed');

      setToken(tokenResponse.access);
      localStorage.setItem('token', tokenResponse.access);
    } catch (error) {
      handleLogout();
    }
  };

  const value = {
    token,
    handleLogin,
    handleLogout,
    handleTokenRefresh,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
