import React, { useEffect } from 'react';
import { PoolUser } from '../../../generated';
import { convertAvatar } from '../../../utils/Converters';
import LoadingCard from '../../../components/layout/cards/LoadingCard';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { roundFloat } from '../../../utils/Format';
import AvatarWithChampion from '../../../components/layout/AvatarWithChampion';
import './PoolsTable.css';

interface PoolUserListProps {
  users: PoolUser[];
}

export const PoolUserList: React.FC<PoolUserListProps> = ({ users }) => {
  const [convertedAvatars, setConvertedAvatars] = React.useState<Array<string | undefined>>([]);

  useEffect(() => {
    const fetchAvatars = async () => {
      const avatars = await Promise.all(users.map(async user => await convertAvatar(user.avatar)));
      setConvertedAvatars(avatars);
    };
    void fetchAvatars();
  }, [users]);

  // if there's any promises pending, return loading
  if (convertedAvatars.length === 0) {
    return <LoadingCard loadingText='Loading pool standing...' />;
  }

  return (
    <List sx={{ maxWidth: '500px' }}>
      <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemAvatar />
        <ListItemText
          primary={
            <Typography variant='body1' fontWeight='bold'>
              {'Name'}
            </Typography>
          }
          sx={{ width: '75%', paddingLeft: '10px' }}
        />
        <ListItemText
          primary={
            <Typography variant='body1' fontWeight='bold'>
              {'Score'}
            </Typography>
          }
        />
      </ListItem>
      {users.map((user, i) => (
        <ListItem key={user.id}>
          <ListItemAvatar>
            <div
              className={
                i === 0 ? 'border-gold' : i === 1 ? 'border-silver' : i === 2 ? 'border-bronze' : ''
              }
              style={{
                height: '60px',
                width: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
              }}>
              <AvatarWithChampion
                sx={{
                  height: '50px',
                  width: '50px',
                  borderRadius: '50%',
                }}
                src={convertedAvatars[i]}
                predictedChampionId={user.predicted_champion?.name}
              />
            </div>
          </ListItemAvatar>
          <ListItemText primary={user.name} sx={{ width: '75%', paddingLeft: '10px' }} />
          <ListItemText primary={roundFloat(user.total_score)} />
        </ListItem>
      ))}
    </List>
  );
};
