import React from 'react';
import { useTranslation } from 'react-i18next';
import MainCard from '../../components/layout/cards/MainCard';
import LoadingCard from '../../components/layout/cards/LoadingCard';
import { Grid, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PositionsTable from '../../components/tables/PositionsTable';
import { useApi } from '../../components/providers/ApiProvider';
import { useAsync } from 'react-use';

const Pools: React.FC = () => {
  const api = useApi();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const userPositions = useAsync(api.v1UsersPoolpositionsList);

  if (userPositions.loading) {
    return <LoadingCard loadingText={t('Pools.LoadingPools')} />;
  }

  if (userPositions.value == null) return;

  return (
    <div data-testid='pools-component' className='App'>
      <Grid container spacing={matchDownMd ? 0 : 0}>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard title={t('Pools.YourPools')} boxShadow={true} border={true}>
            <PositionsTable positions={userPositions.value} />
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard title={t('Pools.CreatePool')} boxShadow={true} border={true}>
            <Button
              type='submit'
              variant='contained'
              sx={{
                width: '100%',
                height: '3em',
                color: 'white',
                backgroundColor: 'darkgreen',
                ':hover': {
                  backgroundColor: theme.palette.secondary.light,
                },
              }}
              onClick={() => {
                navigate('/createpool');
              }}>
              {t('Pools.CreateYourOwnPool')}
            </Button>
          </MainCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Pools;
