import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import MainCard from '../../components/layout/cards/MainCard';
import { useApi } from '../../components/providers/ApiProvider';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const api = useApi();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await api.v1UsersPasswordResetCreate({ email });
      console.log('If the email exists in our system, a recovery email has been sent.');
    } catch (error) {
      console.log('An error occurred while attempting to reset the password. Please try again.');
    } finally {
      setSubmitted(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (submitted) {
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography component='h1' variant='h5'>
            {t('forgotPassword.pageTitle')}
          </Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>
            {t('forgotPassword.instruction', { email })}
          </Typography>
          <Button
            onClick={() => {
              navigate('/login');
            }}
            variant='contained'
            sx={{ mt: 3 }}>
            {t('forgotPassword.returnToLogin')}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '480px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label={t('forgotPassword.emailLabel')}
                name='email'
                autoComplete='email'
                value={email}
                onChange={handleChange}
                autoFocus
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: 'darkgreen',
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                }}>
                {t('forgotPassword.sendRecoveryEmail')}
              </Button>
            </Box>
          </Box>
        </Container>
      </MainCard>
    </div>
  );
};

export default ForgotPassword;
