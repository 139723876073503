import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { AuthContext } from '../../components/providers/AuthProvider';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MainCard from '../../components/layout/cards/MainCard';
import Button from '../../components/layout/Button';
import { useApi } from '../../components/providers/ApiProvider';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface LoginCredentials {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { handleLogin } = React.useContext(AuthContext);
  const api = useApi();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(show => !show);
  };

  const [credentials, setCredentials] = useState<LoginCredentials>({
    username: '',
    password: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setCredentials(prevCredentials => ({ ...prevCredentials, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setUsernameError(false);
    setPasswordError(false);

    try {
      await handleLogin(credentials);
      navigate('/');
      console.log('handling');
    } catch (error: any) {
      if (error.status_code !== 200) {
        enqueueSnackbar(t('login.incorrectCombination'), { variant: 'error' });
        setUsernameError(true);
        setPasswordError(true);
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await api.v1AuthGoogleUrlRetrieve();
      if (response?.auth_url) {
        window.location.href = response.auth_url;
      } else {
        enqueueSnackbar(t('login.authUrlError'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('login.fetchAuthUrlError'), { variant: 'error' });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '480px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component='h1' variant='h5'>
              {t('login.welcomeTitle')}
            </Typography>
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                error={usernameError}
                margin='normal'
                required
                fullWidth
                id='username'
                label='Email address'
                name='username'
                autoComplete='username'
                value={credentials.username}
                onChange={handleChange}
                autoFocus
              />
              <FormControl variant='outlined' margin='normal' required fullWidth>
                <InputLabel
                  htmlFor='outlined-adornment-password'
                  style={passwordError ? { color: '#D32F2F' } : {}}>
                  {t('login.passwordLabel')}
                </InputLabel>
                <OutlinedInput
                  error={passwordError}
                  name='password'
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  value={credentials.password}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
                {passwordError && (
                  <Typography color='error' variant='body2' style={{ marginTop: '8px' }}>
                    {t('login.incorrectCombination')}
                  </Typography>
                )}
              </FormControl>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                }}>
                {t('login.signInButton')}
              </Button>

              <Button
                onClick={handleGoogleLogin}
                fullWidth
                variant='contained'
                sx={{
                  mt: 1,
                  mb: 2,
                  color: '#ffffff', // Text color white

                  padding: '7px 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                startIcon={
                  <img
                    src='/google-logo.png'
                    alt='Google sign-in'
                    style={{ height: '20px', marginRight: '12px' }}
                  />
                }>
                {t('login.signInWithGoogle')}
              </Button>

              <Grid container>
                <Grid item xs>
                  <RouterLink to='/forgotpassword' style={{ fontSize: 'small', color: 'blue' }}>
                    {t('login.forgotPassword')}
                  </RouterLink>
                </Grid>
                <Grid item>
                  <RouterLink to='/register' style={{ fontSize: 'small', color: 'blue' }}>
                    {t('login.register')}
                  </RouterLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </MainCard>
    </div>
  );
};

export default Login;
