import React from 'react';
import { useTranslation } from 'react-i18next';
import MainCard from '../../components/layout/cards/MainCard';
import LoadingCard from '../../components/layout/cards/LoadingCard';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ScoreTable from '../score/components/ScoreTable';
import { useApi } from '../../components/providers/ApiProvider';
import AwardBox, { determineAwards } from './components/AwardBox';
import HelpPopover from '../../components/layout/BasicPopover';
import CurrentGamePredictions from './components/CurrentGamePredictions';
import { useAsync } from 'react-use';
import { PoolUserList } from './components/PoolUserList';
import ShareInviteUrlButton from './components/ShareInviteUrl';

const PoolSingle: React.FC = () => {
  const api = useApi();

  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { poolID } = useParams<{ poolID: string }>();
  if (!poolID) return;
  const poolId = parseInt(poolID);

  const pool = useAsync(async () => {
    return await api.v1PoolsRetrieve(poolId);
  }, []);

  const chart = useAsync(async () => {
    return await api.v1PoolsChartRetrieve(poolId);
  });

  if (pool.loading) {
    return <LoadingCard loadingText='Loading pool...' />;
  }
  if (pool.value == null) return;
  const awards = determineAwards(pool.value.users);

  return (
    <div data-testid='single-pool-component' className='App'>
      <Grid container spacing={matchDownMd ? 0 : 0}>
        <CurrentGamePredictions users={pool.value.users} />

        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={
              <Typography variant='h4' component='h2' style={{ color: 'darkgreen' }}>
                {pool.value.name}
              </Typography>
            }
            contentSX={{ padding: 2 }}
            boxShadow={true}
            border={true}>
            {pool.value.users && <PoolUserList users={pool.value.users} />}
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={t('PoolSingle.Awards')}
            contentSX={{ padding: 2 }}
            boxShadow={true}
            border={true}
            rightTitle={<HelpPopover text={t('PoolSingle.awardExplanationText')} />}>
            <Grid container spacing={matchDownMd ? 0 : 0}>
              <Grid container spacing={matchDownMd ? 0 : 0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AwardBox
                    type='RiskTaker'
                    user={awards.RiskTaker}
                    valueText={t('PoolSingle.averagerisk', 'average risk')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AwardBox
                    type='PerfectPredictor'
                    user={awards.PerfectPredictor}
                    valueText={t('PoolSingle.gamescorrect', 'games correct')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AwardBox
                    type='CautiousCompetitor'
                    user={awards.CautiousCompetitor}
                    valueText={t('PoolSingle.averagerisk', 'average risk')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AwardBox
                    type='LosingStreak'
                    user={awards.LosingStreak}
                    valueText={t('PoolSingle.consecutivegames', 'consecutive games')}
                  />
                </Grid>
              </Grid>{' '}
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {chart.value || chart.loading ? (
            <MainCard
              title={t('PoolSingle.PoolChart')}
              contentSX={{ padding: 2 }}
              boxShadow={true}
              border={true}>
              <img
                src={`data:image/png;base64,${chart.value?.image}`}
                alt={t('PoolSingle.PoolChartImage')}
                style={{ width: '100%', height: 'auto' }}
              />
              <img
                src={`data:image/png;base64,${chart.value?.risk_image}`}
                alt={t('PoolSingle.PoolChartImage')}
                style={{ width: '100%', height: 'auto', marginTop: '10px' }}
              />
            </MainCard>
          ) : (
            <LoadingCard loadingText={t('PoolSingle.LoadingChart')} />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={t('PoolSingle.Stats')}
            contentSX={{ padding: 2 }}
            boxShadow={true}
            border={true}>
            {pool.value.users && <ScoreTable users={pool.value.users} />}
          </MainCard>
        </Grid>

        {pool.value.invite_secret_token && (
          <Grid item xs={12} sm={12} md={12}>
            <MainCard
              title={t('PoolSingle.Invite')}
              contentSX={{ padding: 2 }}
              boxShadow={true}
              border={true}>
              <ShareInviteUrlButton inviteToken={pool.value.invite_secret_token} />
            </MainCard>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PoolSingle;
