import React, { useState } from 'react';
import { Button, TextField, Grid } from '@mui/material';
import { Pool } from '../../../generated'; // Assuming this is the correct import path for Pool type
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { useApi } from '../../../components/providers/ApiProvider';

const CreatePoolForm: React.FC = () => {
  const api = useApi();
  const theme = useTheme();
  const navigate = useNavigate();
  const [poolName, setPoolName] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!poolName) {
      enqueueSnackbar('Poolname is required', {
        variant: 'error',
      });
      return;
    }

    const poolData: Partial<Pool> = {
      name: poolName,
    };

    try {
      const newPool = await api.v1PoolsCreateCreate(poolData as Pool);

      enqueueSnackbar('Successfully created Pool!', {
        variant: 'success',
      });

      navigate(`/pool/${newPool.id}`);
    } catch (error) {
      enqueueSnackbar('Error creating pool', {
        variant: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Pool Name'
            value={poolName}
            onChange={e => {
              setPoolName(e.target.value);
            }}
            fullWidth
            margin='normal'
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type='submit'
            variant='contained'
            sx={{
              width: '100%',
              height: '4em',
              backgroundColor: 'darkgreen',
              fontWeight: 'bold',
              color: 'white',
              ':hover': {
                backgroundColor: theme.palette.secondary.light,
                fontWeight: 'bold',
              },
            }}>
            Create Pool!
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreatePoolForm;
