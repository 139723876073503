import { Prediction } from '../generated';

export function relatedPredictionOrNull(
  predictions: Prediction[] | undefined,
  gameId: number | undefined | null,
): Prediction | null {
  if (!predictions || gameId == null) return null;
  const prediction = predictions.find(prediction => prediction.game.id === gameId);
  return prediction ?? null;
}
