import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Typography,
  Divider,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { roundToOneDecimal } from '../../../utils/Format';
import { useTranslation } from 'react-i18next';

interface ScoreMatrixProps {
  matrix: number[][];
  selectedHomeScore?: number | null;
  selectedAwayScore?: number | null;
  homeTeamName: string;
  awayTeamName: string;
}

const ScoreMatrix: React.FC<ScoreMatrixProps> = ({
  matrix,
  selectedHomeScore,
  selectedAwayScore,
  homeTeamName,
  awayTeamName,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:780px)');
  const theme = useTheme();

  const calculateTotalOdds = (values: number[]): number => {
    const totalInverse = values.reduce((acc, value) => acc + 1 / value, 0);
    return 1 / totalInverse;
  };

  const cellStyle = isMobile
    ? { padding: '5px', fontSize: '10px', textAlign: 'center' as const }
    : { textAlign: 'center' as const };

  const upperCellStyle = {
    padding: isMobile ? '7px' : '16px',
    border: 'none',
    width: '33%',
    textAlign: 'center' as const,
  };
  const slipStyleLeft = {
    padding: '1px 16px 1px 16px',
    border: 'none',
    textAlign: 'left' as const,
  };
  const slipStyleRight = {
    padding: '1px 16px 1px 16px',
    border: 'none',
    fontWeight: 'bold',
    textAlign: 'right' as const,
  };

  const tableStyle = isMobile
    ? { minWidth: 45, width: '100%', display: 'table', marginLeft: 'auto', marginRight: 'auto' }
    : { maxWidth: 550, marginLeft: 'auto', marginRight: 'auto' };

  const selectedStyle = { ...cellStyle, backgroundColor: '#b7e2b7' };
  const upperSelectedStyle = {
    ...cellStyle,
    backgroundColor: '#b7e2b7',
    width: '33%',
    textAlign: 'center' as const,
  };
  const lightSelectedStyle = { ...cellStyle, backgroundColor: '#ecf9ec' };

  const selectedHomeScoreClean = selectedHomeScore ?? -1;
  const selectedAwayScoreClean = selectedAwayScore ?? -1;

  const rowTotals = matrix.map(row => calculateTotalOdds(row));
  const columnTotals = matrix[0].map((_, colIndex) =>
    calculateTotalOdds(matrix.map(row => row[colIndex])),
  );

  const selectedRowTotal = selectedHomeScore == null ? 0 : rowTotals[selectedHomeScoreClean];
  const selectedColumnTotal = selectedAwayScore == null ? 0 : columnTotals[selectedAwayScoreClean];
  let selectedTotal; // Declare `result` variable to ensure it's scoped properly
  if (
    selectedHomeScoreClean >= 0 &&
    selectedAwayScoreClean >= 0 &&
    selectedHomeScoreClean < matrix.length &&
    selectedAwayScoreClean < matrix[selectedHomeScoreClean].length
  ) {
    selectedTotal = matrix[selectedHomeScoreClean][selectedAwayScoreClean];
  } else {
    selectedTotal = 0;
  }

  const calculateOutcomeOdds = () => {
    let homeVictorySum = 0;
    let drawSum = 0;
    let awayVictorySum = 0;

    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        const inverseOdds = 1 / matrix[i][j];
        if (i > j) homeVictorySum += inverseOdds;
        else if (i === j) drawSum += inverseOdds;
        else awayVictorySum += inverseOdds;
      }
    }

    const homeVictoryOdds = 1 / homeVictorySum;
    const drawOdds = 1 / drawSum;
    const awayVictoryOdds = 1 / awayVictorySum;

    return { homeVictoryOdds, drawOdds, awayVictoryOdds };
  };
  const { homeVictoryOdds, drawOdds, awayVictoryOdds } = calculateOutcomeOdds();

  let selectedOutcome;
  let selectedOutcomeValue;
  if (selectedHomeScoreClean >= 0 && selectedAwayScoreClean >= 0) {
    if (selectedHomeScoreClean > selectedAwayScoreClean) {
      selectedOutcome = 'homeVictory';
      selectedOutcomeValue = homeVictoryOdds ?? 0;
    } else if (selectedHomeScoreClean < selectedAwayScoreClean) {
      selectedOutcome = 'awayVictory';
      selectedOutcomeValue = awayVictoryOdds ?? 0;
    } else if (selectedHomeScoreClean == selectedAwayScoreClean) {
      selectedOutcome = 'draw';
      selectedOutcomeValue = drawOdds ?? 0;
    } else {
      selectedOutcome = 'none';
      selectedOutcomeValue = 0;
    }
  }
  const fullScoreCorrect = selectedTotal - selectedRowTotal - selectedColumnTotal;
  const nonNegativeFullScoreCorrect = fullScoreCorrect >= 0 ? fullScoreCorrect : 0;

  return (
    <Grid container justifyContent='center' alignItems='center' spacing={2}>
      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={10}>
        <TableContainer
          component={Paper}
          style={{ width: '100%', backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={selectedOutcome === 'homeVictory' ? upperSelectedStyle : upperCellStyle}>
                  <Typography>{homeTeamName} </Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {roundToOneDecimal(homeVictoryOdds)}
                  </Typography>
                </TableCell>
                <TableCell style={selectedOutcome === 'draw' ? upperSelectedStyle : upperCellStyle}>
                  <Typography>{t('PayoutMatrix.Draw')}</Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {roundToOneDecimal(drawOdds)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={selectedOutcome === 'awayVictory' ? upperSelectedStyle : upperCellStyle}>
                  <Typography>{awayTeamName} </Typography>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    {roundToOneDecimal(awayVictoryOdds)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={10}>
        <TableContainer
          component={Paper}
          elevation={0}
          style={{ backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('explanation.homeScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>{roundToOneDecimal(selectedRowTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('explanation.awayScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(selectedColumnTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('explanation.fullScoreCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(nonNegativeFullScoreCorrect)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('explanation.totoCorrect')}</TableCell>
                <TableCell style={slipStyleRight}>
                  {roundToOneDecimal(selectedOutcomeValue ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={slipStyleLeft}>{t('explanation.totalScoreCorrect')}</TableCell>
                <TableCell
                  style={{
                    ...slipStyleRight,
                    borderTop: '1px solid #000',
                  }}>
                  {roundToOneDecimal(selectedTotal + (selectedOutcomeValue ?? 0))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider variant='middle' sx={{ bgcolor: 'lightgrey', height: 2 }} />
      </Grid>

      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          style={{ ...tableStyle, backgroundColor: theme.palette.primary.light }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={cellStyle}> </TableCell>
                {[0, 1, 2, 3, 4, 5].map(score => (
                  <TableCell key={score} style={cellStyle}>
                    <b>{score !== 5 ? score : '5+'}</b>
                  </TableCell>
                ))}
                <TableCell style={cellStyle}>
                  <b>{t('PayoutMatrix.Home')}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[0, 1, 2, 3, 4, 5].map((homeScore, rowIndex) => (
                <TableRow key={homeScore}>
                  <TableCell component='th' scope='row' style={cellStyle}>
                    <b>{homeScore !== 5 ? homeScore : '5+'}</b>
                  </TableCell>
                  {[0, 1, 2, 3, 4, 5].map((awayScore, colIndex) => {
                    let TotoOdds;
                    if (homeScore > awayScore) {
                      TotoOdds = homeVictoryOdds;
                    } else if (homeScore === awayScore) {
                      TotoOdds = drawOdds;
                    } else {
                      TotoOdds = awayVictoryOdds;
                    }
                    return (
                      <TableCell
                        key={awayScore}
                        style={
                          selectedHomeScoreClean === homeScore &&
                          selectedAwayScoreClean === awayScore
                            ? selectedStyle
                            : selectedHomeScoreClean === homeScore ||
                                selectedAwayScoreClean === colIndex
                              ? lightSelectedStyle
                              : cellStyle
                        }>
                        {roundToOneDecimal(matrix[homeScore][awayScore] + TotoOdds)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={selectedHomeScoreClean === homeScore ? selectedStyle : cellStyle}>
                    <b>{roundToOneDecimal(rowTotals[rowIndex])}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component='th' scope='row' style={cellStyle}>
                  <b>{t('PayoutMatrix.Away')}</b>
                </TableCell>
                {columnTotals.map((total, columnIndex) => (
                  <TableCell
                    key={`total-${columnIndex}`}
                    style={selectedAwayScoreClean === columnIndex ? selectedStyle : cellStyle}>
                    <b>{roundToOneDecimal(total)}</b>
                  </TableCell>
                ))}
                <TableCell style={cellStyle}> </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ScoreMatrix;
