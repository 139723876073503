import React, { CSSProperties, useState } from 'react';
import { List, ListItem, ListItemIcon, Typography, Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import GroupIcon from '@mui/icons-material/Group';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const imageData = [
  {
    img: '/landing_pictures/example_prediction.png',
    title: 'Points based on betting odds',
  },
  {
    img: '/landing_pictures/example_pool.png',
    title: 'Create your own pool',
  },
  {
    img: '/landing_pictures/example_charts.png',
    title: 'See fun stats and charts',
  },
];

export const LandingFeatures: React.FC = () => {
  const { t } = useTranslation();
  const style: CSSProperties = {
    color: 'black',
    fontWeight: 'bold',
  };

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const handleClickOpen = (img: string) => {
    setCurrentImage(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Typography
        variant='h6'
        sx={{ textAlign: 'center', fontWeight: 'bold', marginTop: 3 }}
        style={{ color: 'darkgreen' }}>
        {t('landing.featuresTitle')} 🎲
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon style={style}>
            <StarIcon style={{ color: 'darkgreen' }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: style }} primary={t('landing.feature1')} />
        </ListItem>
        <ListItem style={style}>
          <ListItemIcon style={style}>
            <GroupIcon style={{ color: 'darkgreen' }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: style }} primary={t('landing.feature2')} />
        </ListItem>
        <ListItem style={style}>
          <ListItemIcon style={style}>
            <InsertChartIcon style={{ color: 'darkgreen' }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: style }} primary={t('landing.feature3')} />
        </ListItem>
      </List>

      <Box sx={{ width: '100%', overflow: 'hidden', padding: 5 }}>
        <Slider {...carouselSettings}>
          {imageData.map(item => (
            <div key={item.img} style={{ padding: 2 }}>
              <img
                src={item.img}
                alt={item.title}
                style={{ width: '100%', cursor: 'pointer', border: 'none' }}
                onClick={() => {
                  handleClickOpen(item.img);
                }}
              />
              <Typography variant='body2' style={{ textAlign: 'center', marginTop: 5 }}>
                {item.title}
              </Typography>
            </div>
          ))}
        </Slider>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          {currentImage && <img src={currentImage} alt='Enlarged' style={{ width: '100%' }} />}
        </Box>
      </Dialog>
    </>
  );
};
