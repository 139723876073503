import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../components/providers/ApiProvider';
import MainCard from '../../components/layout/cards/MainCard';

const Unsubscribe = () => {
  const { token } = useParams();
  const api = useApi();
  const [message, setMessage] = useState('Processing...');

  useEffect(() => {
    const handleUnsubscribe = async () => {
      if (token) {
        try {
          const response = await api.v1UnsubscribeCreate(token);
          setMessage(response.message);
        } catch (error) {
          setMessage('Invalid unsubscribe link.');
        }
      } else {
        setMessage('Invalid unsubscribe link.');
      }
    };

    void handleUnsubscribe();
  }, [token, api]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '480px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <h3>{message}</h3>
      </MainCard>
    </div>
  );
};

export default Unsubscribe;
