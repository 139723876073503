import { Box, Typography } from '@mui/material';
import Button from '../../components/layout/Button';
import { useNavigate } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ExplanationText = ({ showButtons }: { showButtons: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        paddingBottom: '40px',
      }}>
      <Typography component='h1' variant='h5' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {t('explanation.explanationTitle')}
      </Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.pointsBasedOnOdds')}
      </Typography>
      <Typography>
        {t('explanation.pointsExplanation')}
        <ul>
          <li>
            <strong>{t('explanation.homeScoreCorrect')}</strong>
          </li>
          <li>
            <strong>{t('explanation.awayScoreCorrect')}</strong>
          </li>
          <li>
            <strong>{t('explanation.fullScoreCorrect')}</strong>
          </li>
          <li>
            <strong>{t('explanation.totoCorrect')}</strong>
          </li>
        </ul>
        {t('explanation.pointsDetails')}
      </Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.homeScoreCorrect')}
      </Typography>
      <Typography>{t('explanation.homeScoreDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.awayScoreCorrect')}
      </Typography>
      <Typography>{t('explanation.awayScoreDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.fullScoreCorrect')}
      </Typography>
      <Typography>{t('explanation.fullScoreDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.totoCorrect')}
      </Typography>
      <Typography>{t('explanation.totoDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.knockout')}
      </Typography>
      <Typography>{t('explanation.knockoutDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
        {t('explanation.knockoutMultiplier')}
      </Typography>
      <Typography>{t('explanation.knockoutMultiplierDescription')}</Typography>
      <Typography component='h2' gutterBottom sx={{ fontWeight: 'bold', mt: 0 }}></Typography>
      <Typography>
        <span style={{ fontWeight: 'bold' }}>1.25x</span>
        <span style={{ whiteSpace: 'pre' }}>{'\t'}</span>
        {t('explanation.8')}
      </Typography>
      <Typography>
        <span style={{ fontWeight: 'bold' }}>1.50x</span>
        <span style={{ whiteSpace: 'pre' }}>{'\t'}</span>
        {t('explanation.4')}
      </Typography>
      <Typography>
        <span style={{ fontWeight: 'bold' }}>1.75x</span>
        <span style={{ whiteSpace: 'pre' }}>{'\t'}</span>
        {t('explanation.2')}
      </Typography>{' '}
      <Typography>
        <span style={{ fontWeight: 'bold' }}>2.00x</span>
        <span style={{ whiteSpace: 'pre' }}>{'\t'}</span>
        {t('explanation.1')}
      </Typography>{' '}
      {showButtons && (
        <div style={{ paddingTop: '1rem' }}>
          <Button
            sx={{ mr: 4 }}
            onClick={() => {
              navigate('/register');
            }}>
            {t('explanation.registerButton')}
          </Button>
          <Button
            onClick={() => {
              navigate('/login');
            }}>
            {t('explanation.loginButton')}
          </Button>
        </div>
      )}
    </Box>
  );
};
