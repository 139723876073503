import { Divider, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { Game, Prediction } from '../../../generated';
import { relatedPredictionOrNull } from '../../../utils/Related';
import React from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/TimeUtils';
import GameText from '../../../components/GameText';
import { useTranslation } from 'react-i18next';

interface PredictionsListProps {
  predictions: Prediction[];
  games: Game[];
}
type TournamentPhase = 'GROUP' | 'EIGHT' | 'QUARTER' | 'HALF' | 'FINAL';

function PredictionsList({ predictions, games }: PredictionsListProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 780px)');
  const { t } = useTranslation();

  const currentDateMinus110Minutes = new Date(new Date().getTime() - 110 * 60000);

  const editableGames = games.filter(game => new Date(game.datetime) > currentDateMinus110Minutes);

  const groupedGames = editableGames.reduce<{ [key in TournamentPhase]?: Game[] }>((acc, game) => {
    const phase = game.tournament_phase as TournamentPhase;
    acc[phase] = acc[phase] ?? [];
    acc[phase]?.push(game);
    return acc;
  }, {});

  // Order of phases
  const phaseOrder: TournamentPhase[] = ['GROUP', 'EIGHT', 'QUARTER', 'HALF', 'FINAL'];
  const phaseTitles: { [key in TournamentPhase]: string } = {
    GROUP: t('PredictionList.GroupStage', 'Group Stage'),
    EIGHT: t('PredictionList.RoundOf16', 'Round of 16'),
    QUARTER: t('PredictionList.QuarterFinals', 'Quarter Finals'),
    HALF: t('PredictionList.SemiFinals', 'Semi Finals'),
    FINAL: t('PredictionList.Final', 'Final'),
  };

  return (
    <div>
      {phaseOrder.map(
        (phase, index) =>
          groupedGames[phase] && (
            <div key={phase}>
              <h2
                style={{
                  textAlign: 'center',
                  color: theme.palette.text.primary,
                  marginTop: index === 0 ? '0px' : '10px',
                }}>
                {phaseTitles[phase]}
              </h2>
              <Divider />

              <List style={{ backgroundColor: theme.palette.primary.light }}>
                {groupedGames[phase]?.map((game, index) => {
                  const predictedHome =
                    relatedPredictionOrNull(predictions, game.id)?.score_home ?? '?';
                  const predictedAway =
                    relatedPredictionOrNull(predictions, game.id)?.score_away ?? '?';
                  const secondaryText = `${predictedHome} - ${predictedAway}`;

                  return (
                    <React.Fragment key={index}>
                      <ListItem className='item'>
                        {isMobile ? (
                          <ListItemText
                            primary={<GameText game={game} variant='body1' iconSize='20px' />}
                            secondary={formatDate(game.datetime)}
                            primaryTypographyProps={{ fontSize: '17px' }}
                            secondaryTypographyProps={{ fontSize: '15px', textAlign: 'center' }}
                          />
                        ) : (
                          <ListItemText
                            primary={<GameText game={game} variant='h6' />}
                            secondary={formatDate(game.datetime)}
                            primaryTypographyProps={{ fontSize: '20px' }}
                            secondaryTypographyProps={{ fontSize: '17px', textAlign: 'center' }}
                          />
                        )}
                      </ListItem>
                      <ListItem style={{ paddingTop: '0px' }} className='item'>
                        <ListItemText
                          primary={secondaryText}
                          secondary={
                            predictedHome === '?' || predictedAway === '?'
                              ? t('PredictionList.FillPrediction', 'Fill in your prediction!')
                              : ''
                          }
                          primaryTypographyProps={{ fontSize: '20px', textAlign: 'center' }}
                          secondaryTypographyProps={{
                            fontSize: '17px',
                            color: theme.palette.secondary.main,
                            textAlign: 'center',
                          }}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primaryTypographyProps={{ textAlign: 'center' }}>
                          <Link to={`/predictions/${game.id}`}>
                            <EditNoteIcon
                              style={{ color: theme.palette.secondary.main, fontSize: '31px' }}
                            />
                          </Link>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </List>
            </div>
          ),
      )}
    </div>
  );
}

export default PredictionsList;
