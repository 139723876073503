import React from 'react';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import MainCard from '../../../components/layout/cards/MainCard';
import { roundFloat } from '../../../utils/Format';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../components/providers/ApiProvider';
import { useAsync } from 'react-use';
import LoadingCard from '../../../components/layout/cards/LoadingCard';

const GlobalRankingCard: React.FC = () => {
  const api = useApi();
  const { t } = useTranslation();
  const globalranking = useAsync(api.v1UsersGlobalrankingRetrieve, []);

  if (globalranking.loading) {
    return <LoadingCard></LoadingCard>;
  }

  if (globalranking.error) {
    return <div>Error loading global ranking</div>;
  }

  if (!globalranking.value) {
    return null;
  }

  return (
    <MainCard title={t('home.ranking', 'Global Ranking')} contentSX={{ padding: 2 }}>
      <List>
        {globalranking.value.top10.map((user, index) => (
          <ListItem key={user.username}>
            <ListItemAvatar>
              <Avatar>{index + 1}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant='body1' fontWeight='bold'>
                  {user.username}
                </Typography>
              }
              secondary={
                <Typography variant='body2' color='text.primary'>
                  {roundFloat(user.points)} points
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Box mt={2}>
        <Typography variant='h6'>{t('home.your_ranking', 'Your Ranking')}</Typography>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{globalranking.value.current_user.position}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant='body1' fontWeight='bold'>
                {globalranking.value.current_user.username}
              </Typography>
            }
            secondary={
              <Typography variant='body2' color='text.primary'>
                {roundFloat(globalranking.value.current_user.points)} points
              </Typography>
            }
          />
        </ListItem>
        <Typography variant='body2' color='text.secondary'>
          {t('home.out_of_users', 'out of')} {globalranking.value.number_of_users}{' '}
          {t('home.users', 'users')}
        </Typography>
      </Box>
    </MainCard>
  );
};

export default GlobalRankingCard;
