import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import MainCard from '../../components/layout/cards/MainCard';
import Button from '../../components/layout/Button';
import { Grid, Link } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useApi } from '../../components/providers/ApiProvider';
import { useTranslation } from 'react-i18next';
import { LandingFeatures } from './LandingFeatures';

const Landing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '550px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          height: '90vh',
          overflow: 'scroll',
        }}>
        <CssBaseline />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography
            component='h1'
            variant='h5'
            sx={{ textAlign: 'center' }}
            style={{ color: 'darkgreen', fontWeight: 'bold' }}>
            {t('landing.title')} ⚽
          </Typography>
          <Typography sx={{ paddingTop: 2, textAlign: 'center' }}>{t('landing.intro')}</Typography>
          <Typography sx={{ paddingTop: 2, textAlign: 'center' }}>{t('landing.intro2')}</Typography>
          <LandingFeatures />
          <Typography
            component='h1'
            variant='h5'
            sx={{ textAlign: 'center' }}
            style={{ fontWeight: 'bold' }}>
            {t('landing.join')} 🚀
          </Typography>
          <LandingButtons />
        </Box>
      </MainCard>
    </div>
  );
};

const LandingButtons = () => {
  const navigate = useNavigate();
  const api = useApi();
  const { t } = useTranslation();

  const handleGoogleLogin = async () => {
    try {
      const response = await api.v1AuthGoogleUrlRetrieve();
      if (response?.auth_url) {
        window.location.href = response.auth_url;
      } else {
        console.error('Auth URL not received:', response);
      }
    } catch (error) {
      console.error('Error fetching auth URL:', error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Link
          onClick={() => {
            navigate('/explanation');
          }}
          style={{
            textDecoration: 'underline',
            color: '#1976D2',
            cursor: 'pointer',
            paddingTop: '1rem',
            display: 'inline-block',
            width: '100%',
          }}>
          <Button
            sx={{ height: '44px', width: '100%' }}
            variant='outlined'
            endIcon={<HelpOutlineIcon />}>
            {t('landing.explanationButton')}
          </Button>
        </Link>
      </Grid>

      <Grid item xs={6}>
        <Button
          sx={{ height: '44px', width: '100%' }}
          onClick={() => {
            navigate('/register');
          }}>
          {t('landing.registerButton')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          sx={{ height: '44px', width: '100%' }}
          onClick={() => {
            navigate('/login');
          }}>
          {t('landing.loginButton')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleGoogleLogin}
          fullWidth
          variant='contained'
          sx={{
            color: '#ffffff',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          startIcon={
            <img
              src='/google-logo.png'
              alt='Google sign-in'
              style={{ height: '20px', marginRight: '12px' }}
            />
          }>
          {t('landing.signInWithGoogle')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Landing;
