import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PoolPosition } from '../../generated';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface PositionsTableProps {
  positions: PoolPosition[];
}

const PositionsTable: React.FC<PositionsTableProps> = ({ positions }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (positions.length === 0) return <div>{t('PositionsTable.noPoolsMessage')}</div>;

  return (
    <TableContainer component={Paper} elevation={0} sx={{ bgcolor: theme.palette.primary.light }}>
      <Table size='small' aria-label='user positions table'>
        <TableHead>
          <TableRow>
            <TableCell>{t('PositionsTable.Name')}</TableCell>
            <TableCell align='left'>{t('PositionsTable.Position')}</TableCell>
            <TableCell align='left'>{t('PositionsTable.NumberOfUsers')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions.map(position => (
            <TableRow key={position.pool}>
              <TableCell component='th' scope='row'>
                <a
                  style={{ color: 'darkgreen', cursor: 'pointer', fontWeight: 'bold' }}
                  onClick={() => {
                    navigate(`/pool/${position.pool_id}/`);
                  }}>
                  {position.pool}
                </a>
              </TableCell>
              <TableCell align='left'>{position.position}</TableCell>
              <TableCell align='left'>{position.number_of_users}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PositionsTable;
