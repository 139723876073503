import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

const customColors = {
  black: '#000000',
  gold: '#DAA520',
  darkGreen: '#006400',
  darkGreenDark: '#005200',
  goldLight: '#FFD700',
  darkGreenLight: '#649964 ',
};

export const themes = {
  [ThemeType.Light]: createTheme({
    palette: {
      primary: {
        main: grey[300],
        light: grey[50],
        dark: grey[500],
      },
      secondary: {
        main: customColors.darkGreen,
        light: customColors.darkGreenLight,
        dark: customColors.darkGreenDark,
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
    },
  }),
  [ThemeType.Dark]: createTheme({
    palette: {
      primary: {
        main: grey[800],
        light: grey[700],
        dark: grey[900],
      },
      secondary: {
        main: customColors.darkGreen,
        light: customColors.darkGreenDark,
        dark: customColors.darkGreenLight,
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.87)',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
      },
    },
  }),
};
