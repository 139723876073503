import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { PoolUser } from '../../../generated';
import { Box } from '@mui/material';

// Function to add ranking based on score
// Deze calculate ik hier inplaats van backend zodat we deze tabel kunnen hergebruiken per pool
const addUserRank = (users: PoolUser[]): PoolUser[] => {
  const sortedUsers = [...users].sort((a, b) => {
    const scoreA = (a.total_score as unknown as number) ?? 0;
    const scoreB = (b.total_score as unknown as number) ?? 0;
    return scoreB - scoreA;
  });

  return sortedUsers.map((user, index) => ({
    ...user,
    rank: index + 1,
  }));
};

const renderScoreCell = (params: GridRenderCellParams): React.ReactElement => {
  const score = typeof params.value === 'number' ? params.value.toFixed(2) : '0';
  return <span>{score}</span>;
};

const columns: GridColDef[] = [
  {
    field: 'rank',
    headerName: '#',
    width: 70,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'name',
    headerName: 'name',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'total_score',
    headerName: 'Score',
    type: 'number',
    flex: 1.5,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderScoreCell,
  },
  {
    field: 'average_odds_of_bets',
    headerName: 'Average odds',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderScoreCell,
  },
  {
    field: 'riskiest_correct_bet',
    headerName: 'Riskiest Correct',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderScoreCell,
  },
  {
    field: 'riskiest_bet',
    headerName: 'Riskiest bet',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderScoreCell,
  },
  {
    field: 'safest_correct_bet',
    headerName: 'Safest Correct',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderScoreCell,
  },
];

interface ScoreTableProps {
  users: PoolUser[];
}

const ScoreTable: React.FC<ScoreTableProps> = ({ users }) => {
  const usersWithRank = addUserRank(users);

  return (
    <Box sx={{ width: '100%', overflow: 'scroll' }}>
      <DataGrid
        sx={{
          minWidth: '850px',
          width: '100%',
        }}
        rows={usersWithRank}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[50, 100]}
        disableRowSelectionOnClick
        disableColumnSelector
      />
    </Box>
  );
};

export default ScoreTable;
