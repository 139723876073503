import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeType, themes } from '../../utils/Themes';
import { BottomNavigation } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router';
import CasinoIcon from '@mui/icons-material/Casino';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NavBar from './navbar/NavBar';
import { useTranslation } from 'react-i18next';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [currentTheme] = React.useState<ThemeType>(ThemeType.Light);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backgroundImageStyle: React.CSSProperties = {
    backgroundImage: 'url(/login_background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  };

  const componentStyle: React.CSSProperties = {
    backgroundColor: 'rgb(211 211 211 / 80%)',
    margin: 'auto',
    maxWidth: '650px',
    height: '100vh',
    overflow: 'scroll',
    scrollbarWidth: 'thin',
    overscrollBehavior: 'none',
  };

  const contentStyle: React.CSSProperties = {
    paddingBottom: '116px',
  };

  return (
    <>
      <div style={backgroundImageStyle}>
        <ThemeProvider theme={themes[currentTheme]}>
          <div style={componentStyle}>
            <NavBar />
            <main style={contentStyle}>{children}</main>
            <BottomNavigation
              showLabels
              sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                maxWidth: '650px',
                backgroundColor: 'darkgreen',
                zIndex: 1000,
              }}>
              <BottomNavigationAction
                sx={{ color: 'white' }}
                label={t('Bottombar.Home')}
                icon={<HomeIcon />}
                onClick={() => {
                  navigate('/');
                }}
              />
              <BottomNavigationAction
                sx={{ color: 'white' }}
                label={t('Bottombar.Predictions')}
                icon={<CasinoIcon />}
                onClick={() => {
                  navigate('/predictions');
                }}
              />
              <BottomNavigationAction
                sx={{ color: 'white' }}
                label={t('Bottombar.Pools')}
                icon={<GroupIcon />}
                onClick={() => {
                  navigate('/pools');
                }}
              />
              <BottomNavigationAction
                sx={{ color: 'white' }}
                label={t('Bottombar.Explanation')}
                icon={<MenuBookIcon />}
                onClick={() => {
                  navigate('/explanations');
                }}
              />
            </BottomNavigation>
          </div>
        </ThemeProvider>
      </div>
    </>
  );
};

export default Layout;
