/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CookieTokenObtainPair } from '../models/CookieTokenObtainPair';
import type { CookieTokenRefresh } from '../models/CookieTokenRefresh';
import type { Email } from '../models/Email';
import type { Game } from '../models/Game';
import type { GamePayout } from '../models/GamePayout';
import type { PasswordReset } from '../models/PasswordReset';
import type { PatchedPredictedChampion } from '../models/PatchedPredictedChampion';
import type { PatchedPredictionUpdate } from '../models/PatchedPredictionUpdate';
import type { PatchedUser } from '../models/PatchedUser';
import type { Pool } from '../models/Pool';
import type { PoolPosition } from '../models/PoolPosition';
import type { PoolSingle } from '../models/PoolSingle';
import type { PoolUser } from '../models/PoolUser';
import type { PredictedChampion } from '../models/PredictedChampion';
import type { Prediction } from '../models/Prediction';
import type { PredictionUpdate } from '../models/PredictionUpdate';
import type { Team } from '../models/Team';
import type { TokenObtainPair } from '../models/TokenObtainPair';
import type { TopUsersAndPositionStats } from '../models/TopUsersAndPositionStats';
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1Service {

    /**
     * Exchange Google authorization code for tokens
     * This endpoint exchanges a Google OAuth authorization code for access and refresh tokens.
     * @param code The authorization code received from Google.
     * @returns any
     * @throws ApiError
     */
    public static v1AuthGoogleCallbackRetrieve(
        code: string,
    ): CancelablePromise<{
        /**
         * Access token used for authentication.
         */
        access?: string;
        /**
         * Refresh token to obtain new access tokens.
         */
        refresh?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/google/callback/',
            query: {
                'code': code,
            },
        });
    }

    /**
     * Retrieve Google OAuth login URL
     * Provides an authentication URL to initiate the Google OAuth login process.
     * @returns any
     * @throws ApiError
     */
    public static v1AuthGoogleUrlRetrieve(): CancelablePromise<{
        auth_url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/google/url/',
        });
    }

    /**
     * @returns Game
     * @throws ApiError
     */
    public static v1GamesList(): CancelablePromise<Array<Game>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/games/',
        });
    }

    /**
     * @param id
     * @returns GamePayout
     * @throws ApiError
     */
    public static v1GamesRetrieve(
        id: number,
    ): CancelablePromise<GamePayout> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/games/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Pool
     * @throws ApiError
     */
    public static v1PoolsList(): CancelablePromise<Array<Pool>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pools/',
        });
    }

    /**
     * @param id
     * @returns PoolSingle
     * @throws ApiError
     */
    public static v1PoolsRetrieve(
        id: number,
    ): CancelablePromise<PoolSingle> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pools/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static v1PoolsChartRetrieve(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pools/{id}/chart',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Pool
     * @throws ApiError
     */
    public static v1PoolsCreateCreate(
        requestBody: Pool,
    ): CancelablePromise<Pool> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pools/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param token
     * @returns any No response body
     * @throws ApiError
     */
    public static v1PoolsJoinCreate(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pools/join/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @returns Prediction
     * @throws ApiError
     */
    public static v1PredictionsList(): CancelablePromise<Array<Prediction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/predictions/',
        });
    }

    /**
     * @param gameId
     * @returns Prediction
     * @throws ApiError
     */
    public static v1PredictionsRetrieve(
        gameId: number,
    ): CancelablePromise<Prediction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/predictions/{game_id}/',
            path: {
                'game_id': gameId,
            },
        });
    }

    /**
     * @param gameId
     * @param requestBody
     * @returns PredictionUpdate
     * @throws ApiError
     */
    public static v1PredictionsUpdate(
        gameId: number,
        requestBody?: PredictionUpdate,
    ): CancelablePromise<PredictionUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/predictions/{game_id}/',
            path: {
                'game_id': gameId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * This patch is actually an UPSERT based on the primary keys of games. It will create a new prediction
     * if it does not exist yet for the user. It is done this way since there is a 1:1 relation with predictions <-> games.
     *
     * If the game's datetime is in the past, it will raise a validation error.
     * @param gameId
     * @param requestBody
     * @returns PredictionUpdate
     * @throws ApiError
     */
    public static v1PredictionsPartialUpdate(
        gameId: number,
        requestBody?: PatchedPredictionUpdate,
    ): CancelablePromise<PredictionUpdate> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/predictions/{game_id}/',
            path: {
                'game_id': gameId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Team
     * @throws ApiError
     */
    public static v1TeamsList(): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/',
        });
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @param requestBody
     * @returns TokenObtainPair
     * @throws ApiError
     */
    public static v1TokenCreate(
        requestBody: CookieTokenObtainPair,
    ): CancelablePromise<TokenObtainPair> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @param requestBody
     * @returns CookieTokenRefresh
     * @throws ApiError
     */
    public static v1TokenRefreshCreate(
        requestBody?: CookieTokenRefresh,
    ): CancelablePromise<CookieTokenRefresh> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/token/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param token
     * @returns any No response body
     * @throws ApiError
     */
    public static v1UnsubscribeCreate(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/unsubscribe/{token}/',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @param requestBody
     * @returns UserCreate
     * @throws ApiError
     */
    public static v1UsersCreate(
        requestBody: UserCreate,
    ): CancelablePromise<UserCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TopUsersAndPositionStats
     * @throws ApiError
     */
    public static v1UsersGlobalrankingRetrieve(): CancelablePromise<TopUsersAndPositionStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/globalranking/',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static v1UsersMeRetrieve(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me/',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static v1UsersMeUpdate(
        requestBody?: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/me/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static v1UsersMePartialUpdate(
        requestBody?: PatchedUser,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/me/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PredictedChampion
     * @throws ApiError
     */
    public static v1UsersMePredictedChampionRetrieve(): CancelablePromise<PredictedChampion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me/predicted-champion/',
        });
    }

    /**
     * @param requestBody
     * @returns PredictedChampion
     * @throws ApiError
     */
    public static v1UsersMePredictedChampionUpdate(
        requestBody?: PredictedChampion,
    ): CancelablePromise<PredictedChampion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/users/me/predicted-champion/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PredictedChampion
     * @throws ApiError
     */
    public static v1UsersMePredictedChampionPartialUpdate(
        requestBody?: PatchedPredictedChampion,
    ): CancelablePromise<PredictedChampion> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/me/predicted-champion/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Endpoint for requesting a password reset email.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static v1UsersPasswordResetCreate(
        requestBody: Email,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/password-reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Endpoint for confirming password reset using `uidb64` and `token` as URL path parameters and a new `password` in the request body.
     * @param token
     * @param uidb64
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static v1UsersPasswordResetConfirmCreate(
        token: string,
        uidb64: string,
        requestBody: PasswordReset,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/password-reset/confirm/{uidb64}/{token}/',
            path: {
                'token': token,
                'uidb64': uidb64,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PoolPosition
     * @throws ApiError
     */
    public static v1UsersPoolpositionsList(): CancelablePromise<Array<PoolPosition>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/poolpositions/',
        });
    }

    /**
     * @returns PoolUser
     * @throws ApiError
     */
    public static v1UsersScorelistList(): CancelablePromise<Array<PoolUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/scorelist/',
        });
    }

}
