import React from 'react';
import './AwardBox.css';
import { PoolUser } from '../../../generated';
import { roundFloat } from '../../../utils/Format';

type AwardType = 'RiskTaker' | 'CautiousCompetitor' | 'LosingStreak' | 'PerfectPredictor';

interface AwardBoxProps {
  type: AwardType;
  user: PoolUser | undefined;
  valueText?: string;
}

const typeToText = {
  RiskTaker: 'Risk Taker',
  CautiousCompetitor: 'Cautious Competitor',
  LosingStreak: 'Longest losing streak',
  PerfectPredictor: 'Perfect Predictor',
};

export function determineAwards(userPoints: PoolUser[]): Record<AwardType, PoolUser | undefined> {
  const sortedDataAverage = userPoints
    .slice()
    .sort((a, b) => a.average_odds_of_bets - b.average_odds_of_bets);
  const sortedDataLosing = userPoints
    .slice()
    .sort((a, b) => a.longest_losing_streak - b.longest_losing_streak);

  const sortedDataPerfect = userPoints
    .slice()
    .sort((a, b) => a.exact_correct_predictions_count - b.exact_correct_predictions_count);

  const result = {
    CautiousCompetitor: sortedDataAverage[0],
    RiskTaker: sortedDataAverage[sortedDataAverage.length - 1],
    LosingStreak: sortedDataLosing[sortedDataLosing.length - 1],
    PerfectPredictor: sortedDataPerfect[sortedDataLosing.length - 1],
  };
  return result;
}

function typeToAwardText(type: AwardType, user: PoolUser): number | string | undefined {
  switch (type) {
    case 'RiskTaker':
      return roundFloat(user.average_odds_of_bets);
    case 'CautiousCompetitor':
      return roundFloat(user.average_odds_of_bets);
    case 'LosingStreak':
      return user.longest_losing_streak;
    case 'PerfectPredictor':
      return user.exact_correct_predictions_count;
  }
}

const AwardBox: React.FC<AwardBoxProps> = ({ type, user, valueText }) => {
  if (!user) return;
  return (
    <div className={`award-box ${type}-award shadow-5 d-flex flex-wrap align-items-center`}>
      <img className='award-icon' src={`/awards/${type}.png`} alt={type} />
      <div className='award-text'>
        <span className='award-title ms-3'>{typeToText[type]}</span>
        <span className='award-user ms-3'>{user.name}</span>
        <span className='award-user ms-3' style={{ fontSize: '15px', textAlign: 'left' }}>
          {' '}
          <b>{typeToAwardText(type, user)}</b> {valueText}
        </span>
      </div>
    </div>
  );
};

export default AwardBox;
