import React from 'react';
import MainCard from '../../components/layout/cards/MainCard';
import { Game } from '../../generated/models/Game';
import LoadingCard from '../../components/layout/cards/LoadingCard';
import { useApi } from '../../components/providers/ApiProvider';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useAsync } from 'react-use';
import PastGamesAccordion from './components/PastGamesAccordion';
import PredictionsList from './components/PredictionList';
import { useTranslation } from 'react-i18next';
import { ChampionPrediction } from './components/ChampionPrediction';
import BasicPopover from '../../components/layout/BasicPopover';

interface PredictionProps {
  games: AsyncState<Game[]>;
}

const Predictions: React.FC<PredictionProps> = ({ games }) => {
  const { t } = useTranslation();
  const api = useApi();
  const predictions = useAsync(api.v1PredictionsList);
  const teams = useAsync(api.v1TeamsList);

  if (predictions.loading || teams.loading || games.loading) {
    return <LoadingCard height='810px' loadingText={t('PredictionsPage.loadingPredictions')} />;
  }
  if (predictions.value == null || teams.value == null || games.value == null) return;

  const isTournamentStarted = games.value.some(game => new Date(game.datetime) < new Date());

  const sortedTeams = teams.value.sort((a, b) => a.championship_points - b.championship_points);
  const formattedText = sortedTeams
    .map(team => `${team.name}\t - ${team.championship_points.toFixed(0)}`)
    .join('\n');
  const championHelpText = t('PredictionsPage.championHelp');

  return (
    <div>
      {!isTournamentStarted && (
        <MainCard
          contentSX={{ padding: 2 }}
          boxShadow={true}
          border={true}
          title={t('PredictionsPage.champion')}
          rightTitle={<BasicPopover text={t('PredictionsPage.championHelp')} />}>
          <ChampionPrediction teams={teams.value} disabled={isTournamentStarted} />
        </MainCard>
      )}

      {isTournamentStarted && (
        <MainCard
          contentSX={{ padding: '0', paddingBottom: '0 !important' }}
          boxShadow={true}
          border={true}
          title={t('PredictionsPage.pastGames')}>
          <PastGamesAccordion predictions={predictions.value} games={games.value} />
        </MainCard>
      )}

      <MainCard
        contentSX={{ padding: 2 }}
        boxShadow={true}
        border={true}
        title={t('PredictionsPage.upcomingGames')}
        rightTitle={<BasicPopover text={t('PredictionsPage.predictionsHelp')} />}>
        <PredictionsList predictions={predictions.value} games={games.value} />
      </MainCard>

      {/* Put chamption prediction at the bottom when tournament has started */}
      {isTournamentStarted && (
        <MainCard
          contentSX={{ padding: 2 }}
          boxShadow={true}
          border={true}
          title={t('PredictionsPage.champion')}
          rightTitle={
            <BasicPopover
              text={`${championHelpText}\n\n**Potential champion points**\n${formattedText}`}
            />
          }>
          <ChampionPrediction teams={teams.value} disabled={isTournamentStarted} />
        </MainCard>
      )}
    </div>
  );
};

export default Predictions;
