// function to return a string that is rounded
export function roundFloat(num?: number): string | undefined {
  if (num == null) {
    return undefined;
  }
  return num.toFixed(0);
}

// console.log(roundToOneDecimal(123.456));   // Output: "123.5"
// console.log(roundToOneDecimal("123.456")); // Output: "123.5"
// console.log(roundToOneDecimal("N/A"));     // Output: "N/A"
// console.log(roundToOneDecimal(undefined)); // Output: ""
export const roundToOneDecimal = (num: number | string | undefined): string => {
  if (num == undefined) {
    return '';
  }
  if (typeof num === 'string' && isNaN(parseFloat(num))) {
    return num;
  }
  const numberInput = typeof num === 'string' ? parseFloat(num) : num;
  return numberInput.toFixed(1);
};
