import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { AuthContext } from '../../components/providers/AuthProvider';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { ApiError } from '../../generated';
import MainCard from '../../components/layout/cards/MainCard';
import { useApi } from '../../components/providers/ApiProvider';
import { useTranslation } from 'react-i18next';

interface RegistrationCredentials {
  email: string;
  password: string;
  confirmPassword: string;
}

const Registration: React.FC = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { handleLogin } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const [invalidPasswordError, setInvalidPasswordError] = React.useState(false);
  const [emailExistsError, setEmailExistsError] = React.useState(false);

  const [registrationCredentials, setRegistrationCredentials] = useState<RegistrationCredentials>({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setRegistrationCredentials(prevCredentials => ({ ...prevCredentials, [name]: value }));
  };

  const handleRegistrationSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (registrationCredentials.password !== registrationCredentials.confirmPassword) {
      setInvalidPasswordError(true);
      console.error(t('registration.passwordsDoNotMatch'));
      enqueueSnackbar(t('registration.passwordsDoNotMatch'), {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });

      return;
    }

    if (registrationCredentials.password.length < 8) {
      setInvalidPasswordError(true);
      console.error(t('registration.passwordTooShort'));
      enqueueSnackbar(t('registration.passwordTooShort'), {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });

      return;
    }

    try {
      await api.v1UsersCreate({
        email: registrationCredentials.email,
        password: registrationCredentials.password,
      });
      await handleLogin({
        username: registrationCredentials.email,
        password: registrationCredentials.password,
      });

      navigate('/');
    } catch (error) {
      if (error instanceof ApiError && error.status === 409) {
        setEmailExistsError(true);
        return;
      }
      throw error;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <MainCard
        border={false}
        sx={{
          maxWidth: '480px',
          width: '100%',
          padding: '0.5rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component='h1' variant='h5'>
              {t('registration.createAccount')}
            </Typography>

            <Box component='form' onSubmit={handleRegistrationSubmit} sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                type='email'
                id='email'
                label={t('registration.emailAddress')}
                name='email'
                autoComplete='email'
                value={registrationCredentials.email}
                onChange={handleRegistrationChange}
              />
              <FormControl variant='outlined' margin='normal' required fullWidth>
                <InputLabel htmlFor='outlined-adornment-password'>
                  {t('registration.password')}
                </InputLabel>
                <OutlinedInput
                  name='password'
                  id='outlined-adornment-password'
                  type='password'
                  value={registrationCredentials.password}
                  onChange={handleRegistrationChange}
                  label={t('registration.password')}
                  error={invalidPasswordError}
                />
              </FormControl>

              <FormControl variant='outlined' margin='normal' required fullWidth>
                <InputLabel htmlFor='outlined-adornment-confirm-password'>
                  {t('registration.confirmPassword')}
                </InputLabel>
                <OutlinedInput
                  name='confirmPassword'
                  id='outlined-adornment-confirm-password'
                  type='password'
                  value={registrationCredentials.confirmPassword}
                  onChange={handleRegistrationChange}
                  label={t('registration.confirmPassword')}
                />
              </FormControl>

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: 'darkgreen',
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                }}>
                {t('registration.signUp')}
              </Button>

              <Typography color='error'>
                {emailExistsError && t('registration.emailExists')}
              </Typography>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <RouterLink to='/login' style={{ fontSize: 'small', color: 'blue' }}>
                    {t('registration.signIn')}
                  </RouterLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </MainCard>
    </div>
  );
};

export default Registration;
