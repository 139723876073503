// GamesAccordion.tsx
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Game, Prediction } from '../../../generated';
import { relatedPredictionOrNull } from '../../../utils/Related';
import { formatDate } from '../../../utils/TimeUtils';
import { roundToOneDecimal } from '../../../utils/Format';
import GameText from '../../../components/GameText';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface PayoutItemProps {
  payout: number | undefined;
  isMobile: boolean;
}

const PayoutText: React.FC<PayoutItemProps> = ({ payout, isMobile }) => {
  const size = isMobile ? '30px' : '50px';
  const fontSize = isMobile ? '10px' : '16px';
  const lineHeight = isMobile ? '23px' : '40px';

  let style: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: lineHeight,
    width: size,
    height: size,
  };

  if (payout != undefined && payout > 0) {
    style = {
      ...style,
      backgroundColor: '#069a025e',
      borderRadius: '50%',
    };
  }

  return (
    <div style={style}>
      <ListItemText
        primaryTypographyProps={{ lineHeight: lineHeight, fontSize: fontSize }}
        primary={roundToOneDecimal(payout)}
      />
    </div>
  );
};

interface GamesAccordionProps {
  predictions: Prediction[];
  games: Game[];
}

const GamesAccordion: React.FC<GamesAccordionProps> = ({ predictions, games }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:780px)');

  const primaryFontSize = isMobile ? '12px' : '18px';
  const secondaryFontSize = isMobile ? '10px' : '15px';

  const pastGames = games.filter(game => new Date(game.datetime) < new Date(Date.now()));

  return (
    <Accordion style={{ backgroundColor: 'rgba(255, 0, 0, 0)' }}>
      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
        <Typography>{t('PredictionsPage.unfoldClick')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {pastGames.map((game, index) => {
            const prediction = relatedPredictionOrNull(predictions, game.id);

            return (
              <ListItem
                key={index}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgb(6 154 2 / 19%)',
                  },
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/predictions/${game.id}`);
                }}>
                <ListItemText
                  primary={
                    <GameText
                      game={game}
                      iconSize='20px'
                      variant='body1'
                      style={{
                        fontSize: primaryFontSize,
                        verticalAlign: 'center',
                      }}
                    />
                  }
                  secondary={formatDate(game.datetime)}
                  primaryTypographyProps={{ fontSize: primaryFontSize }}
                  secondaryTypographyProps={{ fontSize: secondaryFontSize, textAlign: 'center' }}
                />
                <PayoutText payout={prediction?.payout} isMobile={isMobile} />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default GamesAccordion;
