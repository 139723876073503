import React from 'react';
import { Grid, Typography, TypographyProps } from '@mui/material';
import { Game } from '../generated';
import { getTeamFlagSrc } from '../utils/FlagImages';

export interface GameTextProps extends TypographyProps {
  game: Game;
  iconSize?: string;
  showScore?: boolean;
  style?: React.CSSProperties;
  showLive?: boolean;
}

const GameText: React.FC<GameTextProps> = ({
  game,
  iconSize,
  showScore,
  style,
  showLive,
  ...typographyProps
}) => {
  const homeFlag = getTeamFlagSrc(game.team_home?.name);
  const awayFlag = getTeamFlagSrc(game.team_away?.name);
  const imgStyle: React.CSSProperties = {
    padding: '5px',
    width: iconSize ?? '30px',
    height: iconSize ?? '30px',
    position: 'relative',
    bottom: '5px',
  };

  const gameDurationMinutes = 110;
  const gameStartTime = new Date(game.datetime);
  const gameEndTime = new Date(gameStartTime.getTime() + gameDurationMinutes * 60000);
  const isGameCurrentlyPlaying = gameStartTime <= new Date() && new Date() <= gameEndTime;

  return (
    <>
      {isGameCurrentlyPlaying && showLive && (
        <img src='/live.gif' width='50' style={{ margin: 'auto' }} />
      )}
      <Grid container alignItems='center' spacing={1} style={{ width: '100%', ...style }}>
        <Grid item xs={6}>
          <span style={{ display: 'flex', float: 'right', paddingRight: '10px' }}>
            <Typography {...typographyProps}>{game.team_home?.name}</Typography>
            <img src={homeFlag} alt={`${game.team_home?.name} Flag`} style={imgStyle} />

            {showScore && <Typography {...typographyProps}>{game.score_home}</Typography>}
          </span>
        </Grid>
        <Grid item xs={0} style={{ padding: 0, margin: 0 }}>
          <Typography {...typographyProps} style={{ float: 'left' }}>
            -
          </Typography>
        </Grid>
        <Grid item xs={5} style={{ paddingLeft: '10px' }}>
          <span style={{ display: 'flex', float: 'left' }}>
            {showScore && <Typography {...typographyProps}>{game.score_away}</Typography>}
            <img src={awayFlag} alt={`${game.team_away?.name} Flag`} style={imgStyle} />
            <Typography {...typographyProps}>{game.team_away?.name}</Typography>
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default GameText;
