import React, { useState } from 'react';
import { Game, Prediction } from '../../../generated';
import { formatDateTimeSeparate } from '../../../utils/TimeUtils';
import { Divider, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MainCard from '../../../components/layout/cards/MainCard';
import { relatedPredictionOrNull } from '../../../utils/Related';
import GameText from '../../../components/GameText';
import { useTranslation } from 'react-i18next';

function getTodaysOrUpcomingGames(games: Game[] | undefined): Game[] {
  if (!games) return [];

  const sortedGames = games
    .slice()
    .sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());

  const currentDateTime = new Date();

  // Find the first upcoming game
  let referenceGame: Game | undefined = sortedGames.find(game => {
    const gameEndDateTime = new Date(game.datetime);
    gameEndDateTime.setMinutes(gameEndDateTime.getMinutes() + 110);
    return gameEndDateTime > currentDateTime;
  });

  // If no upcoming games, use the next available game
  if (!referenceGame) {
    referenceGame = sortedGames.find(game => new Date(game.datetime) > currentDateTime);
  }

  if (!referenceGame) return [];

  const referenceDate = new Date(referenceGame.datetime).toDateString();

  // Return all games on the same day as the reference game
  return sortedGames.filter(game => new Date(game.datetime).toDateString() === referenceDate);
}

const checkIfTournamentIsOver = (games: Game[]): boolean => {
  return games.every(game => new Date(game.datetime) < new Date());
};

interface UpcomingGamesCardProps {
  games: Game[];
  predictions: Prediction[];
}

export const UpcomingGamesCard: React.FC<UpcomingGamesCardProps> = ({ games, predictions }) => {
  if (checkIfTournamentIsOver(games)) return;

  const theme = useTheme();
  const { t } = useTranslation();

  const [nextPredictions, setNextPredictions] = useState<Array<Prediction | undefined | null>>([]);

  const nextGames = getTodaysOrUpcomingGames(games);

  if (nextPredictions.length === 0) {
    const newPredictions = nextGames.map(game => relatedPredictionOrNull(predictions, game.id));
    setNextPredictions(newPredictions);
  }

  if (nextPredictions[0] === undefined && nextPredictions[1] === undefined) return;
  if (nextGames.length === 0) return;

  return (
    <MainCard title={t('home.upcoming')} contentSX={{ padding: '25px' }}>
      {nextGames.map((game, index) => (
        <div key={index} style={{ marginBottom: '20px', padding: '10px', textAlign: 'center' }}>
          <GameText game={game} variant='body1' showLive={true} showScore={true} iconSize='20px' />
          <Typography variant='body1'>{formatDateTimeSeparate(game.datetime).date}</Typography>
          <Typography variant='body1'>{formatDateTimeSeparate(game.datetime).time}</Typography>
          {nextPredictions[index] && (
            <div style={{ marginTop: '15px' }}>
              <Typography variant='body1'>
                <b>{t('home.prediction')}</b>
              </Typography>
              <Typography variant='body1'>
                {nextPredictions[index]?.score_home} - {nextPredictions[index]?.score_away}
              </Typography>
              <Link to={`/predictions/${game.id}`}>
                <EditNoteIcon style={{ color: theme.palette.secondary.main }} />
              </Link>
            </div>
          )}
          {/* if no prediction is set, nudge user to make one */}
          {!nextPredictions[index] && (
            <div style={{ marginTop: '15px' }}>
              <Typography variant='body1'>
                <b>{t('home.noPredictionYet')}</b>
              </Typography>
              <Link to={`/predictions/${game.id}`}>
                <EditNoteIcon style={{ color: theme.palette.secondary.main }} />
              </Link>
            </div>
          )}
          <Divider style={{ paddingTop: '10px' }} />
        </div>
      ))}
    </MainCard>
  );
};
