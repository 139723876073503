import * as React from 'react';
import { ReactNode, forwardRef, Ref } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface MainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children?: ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: object;
  darkTitle?: boolean;
  secondary?: ReactNode | string;
  shadow?: string;
  sx?: object;
  title?: ReactNode | string;
  rightTitle?: ReactNode | string;
}
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      rightTitle,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          borderRadius: '10px',
          margin: 1,

          ':hover': {
            boxShadow: boxShadow ? shadow ?? '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit',
          },
          ...sx,
        }}>
        {title && (
          <CardHeader
            sx={headerSX}
            title={
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ paddingLeft: '4px' }}>
                  <Typography variant='h5'>{title}</Typography>
                </div>
                {rightTitle && (
                  <div>
                    <Typography fontWeight={'bold'} variant='h5'>
                      {rightTitle}
                    </Typography>
                  </div>
                )}
              </div>
            }
            action={secondary}
          />
        )}
        {title && <Divider />}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  },
);

MainCard.displayName = 'MainCard';

export default MainCard;
