import React from 'react';
import { useTranslation } from 'react-i18next';
import MainCard from '../../components/layout/cards/MainCard';
import PositionsTable from '../../components/tables/PositionsTable';
import LoadingCard from '../../components/layout/cards/LoadingCard';
import { useApi } from '../../components/providers/ApiProvider';
import { useAsync } from 'react-use';
import { UpcomingGamesCard } from './components/UpcomingGamesCard';
import { Game } from '../../generated';
import GlobalRankingCard from './components/GlobalRankingCard';

const isTournamentInProgress = (games: Game[]): boolean => {
  return games.some(game => new Date(game.datetime) < new Date());
};

const Home: React.FC = () => {
  const api = useApi();

  const { t } = useTranslation();
  const games = useAsync(api.v1GamesList);
  const userPositions = useAsync(api.v1UsersPoolpositionsList);
  const predictions = useAsync(api.v1PredictionsList);

  if (games.loading || userPositions.loading || predictions.loading) {
    return <LoadingCard loadingText={t('Other.Loading')} />;
  }

  if (games.value == null || userPositions.value == null || predictions.value == null) return null;

  return (
    <div>
      <UpcomingGamesCard games={games.value} predictions={predictions.value} />
      <MainCard title={t('home.pools')} contentSX={{ padding: 2 }}>
        <PositionsTable positions={userPositions.value} />
      </MainCard>
      {isTournamentInProgress(games.value) && <GlobalRankingCard />}
    </div>
  );
};

export default Home;
